export function getDigitString(str) {
  return str.replace(/[^\d]/g, '');
}

export default function numericInput(maxLength) {
  return (value) => {
    if (!value) {
      return value;
    }

    let truncatedValue;

    if (maxLength) {
      truncatedValue = value.slice(0, maxLength);
    } else {
      truncatedValue = value;
    }

    return getDigitString(truncatedValue);
  };
}
