import classNames from 'clsx';
import get from 'lodash/get';
import PropTypes from 'prop-types';
import { useContext, useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { updateGoogleAuthSdkStatus } from '../../../actions/auth';
import { oAuthSdkStates } from '../../../constants/auth/oauth';
import {
  OAUTH_GAPI_SIGNIN_ERROR,
  OAUTH_GAPI_SIGNUP_ERROR,
} from '../../../constants/localizations/auth';
import { LocationAndLocalizationContext } from '../../../providers/LocationAndLocalizationProvider';
import {
  doGoogleSignIn,
  setupGoogleAuth,
} from '../../../utils/auth/googleAuth';
import Text from '../../Text';
import LoadingButton from '../../shared/button/LoadingButton';
import PillButton from '../../shared/button/PillButton';
import GoogleIcon from '../../shared/svgIcons/GoogleIcon';
import css from './oauth-button.module.scss';

// exported for testing
export const GoogleButton = ({
  googleAuthSdkStatus,
  buttonText,
  actionHandler,
  partnerParams,
  source,
  isSignIn,
  isPartner,
  onClick,
  actions,
}) => {
  const { getLocalizedText } = useContext(LocationAndLocalizationContext);
  const isErrorStatus = googleAuthSdkStatus === oAuthSdkStates.error;

  // componentDidMount
  useEffect(() => {
    setupGoogleAuth((err) => {
      actions.updateGoogleAuthSdkStatus(
        err ? oAuthSdkStates.error : oAuthSdkStates.success,
      );
    }, isErrorStatus);
  }, []); /* eslint-disable-line react-hooks/exhaustive-deps */

  const handleClick = () => {
    if (onClick) onClick();
    doGoogleSignIn(actionHandler, source, partnerParams);
  };

  const errorText = isSignIn
    ? getLocalizedText(OAUTH_GAPI_SIGNIN_ERROR)
    : getLocalizedText(OAUTH_GAPI_SIGNUP_ERROR);

  const customIconProps = isPartner
    ? {
        width: '24',
        height: '24',
        viewBox: '0 0 22 22',
      }
    : {
        width: '16',
        height: '16',
      };

  return (
    <>
      {googleAuthSdkStatus === oAuthSdkStates.loading ? (
        <LoadingButton
          className={classNames(css.googleButton, {
            [css.isPartnerFlow]: isPartner,
          })}
        />
      ) : (
        <PillButton
          id="googleOauthButton"
          className={classNames(css.googleButton, {
            [css.isPartnerFlow]: isPartner,
          })}
          label={<Text name={buttonText} />}
          icon={<GoogleIcon {...customIconProps} />}
          onClick={handleClick}
        />
      )}
      {isErrorStatus && <Text name={errorText} className={css.errorText} />}
    </>
  );
};

GoogleButton.propTypes = {
  actionHandler: PropTypes.func.isRequired,
  buttonText: PropTypes.string.isRequired,
  googleAuthSdkStatus: PropTypes.string.isRequired,
  actions: PropTypes.object.isRequired,
  onClick: PropTypes.func,
  partnerParams: PropTypes.object,
  isPartner: PropTypes.bool,
  source: PropTypes.string,
};

export const mapStateToProps = (state) => ({
  googleAuthSdkStatus: get(state, 'auth.googleAuthSdkStatus'),
});

export const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({ updateGoogleAuthSdkStatus }, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(GoogleButton);
