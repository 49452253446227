import classnames from 'clsx';
import PropTypes from 'prop-types';
import { Component } from 'react';

import {
  ALREADY_HAVE_AN_ACCOUNT,
  CREATE_AN_ACCOUNT,
  DONT_HAVE_AN_ACCOUNT,
  SIGN_IN,
} from 'src/common/constants/localizations/auth';
import UnderlineLink from '../../shared/link/UnderlineLink';

import keyCodes from '../../../constants/keyCodes';
import { LocationAndLocalizationContext } from '../../../providers/LocationAndLocalizationProvider';
import css from './secondary-content.module.scss';

class SecondaryContent extends Component {
  static propTypes = {
    isPartnerFlow: PropTypes.bool,
    handler: PropTypes.func,
    onSignIn: PropTypes.bool,
  };

  static contextType = LocationAndLocalizationContext;

  onSignInLayout() {
    const { getLocalizedText } = this.context;
    const { isPartnerFlow } = this.props;

    return (
      <div
        data-testid="secondaryContentOuter"
        className={classnames(css.secondaryContentOuter, {
          [css.isPartnerFlow]: isPartnerFlow,
        })}
      >
        {getLocalizedText(DONT_HAVE_AN_ACCOUNT)}
        <UnderlineLink
          /* biome-ignore lint/a11y/useSemanticElements: invalid lint error for `role` on React Component */
          role="button"
          tabIndex="0"
          data-testid="secondaryContentInnerFirst"
          underlineClassName={classnames(css.link, css.createAccount, {
            [css.isPartnerFlow]: isPartnerFlow,
          })}
          className={classnames(css.link, {
            [css.isPartnerFlow]: isPartnerFlow,
          })}
          onKeyUp={this.handleKeyUp}
          onClick={this.props.handler}
          isDropDown
        >
          {getLocalizedText(CREATE_AN_ACCOUNT)}
        </UnderlineLink>
      </div>
    );
  }

  onSignUpLayout() {
    const { getLocalizedText } = this.context;
    const { isPartnerFlow } = this.props;

    return (
      <div
        data-testid="secondaryContentOuter"
        className={classnames(css.secondaryContentOuter, {
          [css.isPartnerFlow]: isPartnerFlow,
        })}
      >
        {getLocalizedText(ALREADY_HAVE_AN_ACCOUNT)}
        <UnderlineLink
          /* biome-ignore lint/a11y/useSemanticElements: invalid lint error for `role` on React Component */
          role="button"
          tabIndex="0"
          data-testid="secondaryContentInnerSecond"
          underlineClassName={classnames(css.link, css.signIn, {
            [css.isPartnerFlow]: isPartnerFlow,
          })}
          className={classnames(css.link, {
            [css.isPartnerFlow]: isPartnerFlow,
          })}
          onKeyUp={this.handleKeyUp}
          onClick={this.props.handler}
          isDropDown
        >
          {getLocalizedText(SIGN_IN)}
        </UnderlineLink>
      </div>
    );
  }

  handleKeyUp = (event) => {
    if (event.keyCode === keyCodes.ENTER && this.props.handler) {
      this.props.handler(event);
    }
  };

  render() {
    return this.props.onSignIn ? this.onSignInLayout() : this.onSignUpLayout();
  }
}

export default SecondaryContent;
