import noop from 'lodash/noop';
import PropTypes from 'prop-types';
import { LOADING } from '../../../constants/localizations/app';
import Text from '../../Text';
import PillButton from './PillButton';

const LoadingButton = ({ className, isDisabled }) => (
  <PillButton
    id="loadingButton"
    className={className}
    label={<Text name={LOADING} />}
    onClick={noop}
    isDisabled={isDisabled}
  />
);

LoadingButton.propTypes = {
  className: PropTypes.string,
  isDisabled: PropTypes.bool,
};

export default LoadingButton;
