import classNames from 'clsx';
import PropTypes from 'prop-types';
import { Component } from 'react';
import { Field, Form } from 'react-final-form';
import { connect } from 'react-redux';
import {
  fbPixelCustomEvents,
  fbPixelStandardEvents,
  fbTrack,
} from 'src/client/analytics/facebook/fbTrack';
import {
  hintStyle,
  inputStyles,
} from 'src/common/components/shared/inputSharedStyles';
import {
  FAULTCODE_BIRTH,
  FAULTCODE_USERNAME_EXISTS,
} from 'src/common/constants/faultCodes';
import {
  BIRTH_YEAR,
  BIRTH_YEAR_REQUIRED,
  BIRTH_YEAR_TOO_YOUNG,
  NEXT,
  SIGN_UP,
  SIGN_UP_FAILED,
} from 'src/common/constants/localizations/auth';
import {
  EMAIL_ADDRESS,
  EMAIL_ALREADY_EXISTS,
  EMAIL_REQUIRED,
  FEMALE_LABEL,
  MALE_LABEL,
  NAME,
  NONBINARY_LABEL,
  PASSWORD,
  PASSWORD_REQUIRED,
  PREFERNOTTOSAY_LABEL,
} from 'src/common/constants/localizations/shared';
import { SOURCE_ONAIR } from 'src/common/constants/sourceQueryParams';
import { getPartnerParams } from 'src/common/utils/auth/partnerAuthFlowHelpers';
import { logClientError, logSignUpClick } from '../../actions/logging';
import account from '../../constants/analytics/categoryActionLabel/account';
import { recaptchaActions } from '../../constants/auth/recaptcha';
import {
  AUTH_EMAIL_SIGNUP_ENABLED,
  AUTH_EMAIL_SIGNUP_RECAPTCHA_ENABLED,
} from '../../constants/experiments/auth';
import { LocationAndLocalizationContext } from '../../providers/LocationAndLocalizationProvider';
import { selectExperiment } from '../../selectors/config';
import { getRecaptchaToken } from '../../utils/auth/recaptcha';
import numericInputNormalizer from '../../utils/form/normalizers/numericInput';
import {
  baseError,
  composeValidators,
  isRequired,
  isValidBirthYear,
  isValidEmail,
  isValidFullName,
  isValidPassword,
} from '../../utils/form/validation';
import InkButton from '../shared/button/InkButton';
import PremiumGoldButton from '../shared/button/PremiumGoldButton';
import ShowPasswordButton from '../shared/button/ShowPasswordButton';
import Input from '../shared/form/Input';
import { RadioButton } from '../shared/form/RadioButton';
import TopLevelErrorContainer from '../shared/form/TopLevelErrorContainer';
import {
  inputCheckmarkSvgProps,
  inputMappedPartnerAuthStyles,
} from '../shared/styles/partnerAuthSharedStyles';
import Checkmark from '../shared/svgIcons/LegacyCheckmark';
import checkFieldsForValidInput from '../utils/checkFieldsForValidInput';
import authCss from './auth.module.scss';
import AuthFlowPromoOfferFinePrint from './shared/AuthFlowPromoOfferFinePrint';
import AuthSectionDivider from './shared/AuthSectionDivider';
import GeneralNotices from './shared/GeneralNotices';
import OauthButtons from './shared/OauthButtons';
import SecondaryContent from './shared/SecondaryContent';
import css from './sign-up-view.module.scss';

const fieldOrder = ['name', 'email', 'password', 'birthYear'];

const svgProps = {
  ...inputCheckmarkSvgProps,
  className: authCss.checkmark,
};

/* eslint-disable no-unused-vars */
// exported for testing
export const RadioButtonWrapper = ({ input = {}, isPartner, ...rest }) => (
  <RadioButton
    {...input}
    className={classNames(css.signUpOption, {
      [css.isPartnerFlow]: isPartner,
    })}
    labelClassName={classNames(css.signUpOptionLabel, {
      [css.isPartnerFlow]: isPartner,
    })}
    spanClassName={css.customSpanClassName}
    defaultChecked={input.checked}
    handleClick={(evt) => {
      input.onChange(evt.target.value);
    }}
    {...rest}
  />
);
/* eslint-enable no-unused-vars */

class SignUpView extends Component {
  static propTypes = {
    actions: PropTypes.shape({
      createAccount: PropTypes.func.isRequired,
      createAccountWithOauth: PropTypes.func.isRequired,
      trackSignUpPage: PropTypes.func.isRequired,
    }).isRequired,
    logSignUpClick: PropTypes.func.isRequired,
    logClientError: PropTypes.func.isRequired,
    handleChangeToSignIn: PropTypes.func.isRequired,
    viewTypeData: PropTypes.object.isRequired,
    failAuthNotice: PropTypes.string,
    queryProduct: PropTypes.object,
    isDialog: PropTypes.bool,
    isPartner: PropTypes.bool,
    source: PropTypes.string,
    isPaymentFlow: PropTypes.bool,
    isEmailSignUpEnabled: PropTypes.bool.isRequired,
    isRecaptchaV3Enabled: PropTypes.bool.isRequired,
  };

  static contextType = LocationAndLocalizationContext;

  constructor(props) {
    super(props);

    this.state = {
      showPassword: false,
    };

    this.handleOAuthSignUp = this.handleOAuthSignUp.bind(this);
    this.handleShowPasswordClick = this.handleShowPasswordClick.bind(this);
  }

  componentDidMount() {
    fbTrack(fbPixelCustomEvents.SignUpPageVisit);
    this.props.actions.trackSignUpPage();
  }

  handleOAuthSignUp(...oauthData) {
    const { actions, viewTypeData } = this.props;
    actions.createAccountWithOauth(...oauthData, { viewTypeData }).then(() => {
      fbTrack(fbPixelStandardEvents.CompleteRegistration);
    });
  }

  onSubmit = async (values, form) => {
    const {
      actions,
      isPartner,
      source,
      viewTypeData,
      isRecaptchaV3Enabled,
      logClientError: onRecaptchaError,
    } = this.props;
    const { location } = this.context;
    const { name, password, email, birthYear, gender } = values;
    const recaptchaToken = isRecaptchaV3Enabled
      ? await getRecaptchaToken(recaptchaActions.emailSignUp, onRecaptchaError)
      : null;

    fbTrack(fbPixelStandardEvents.CompleteRegistration);
    this.props.logSignUpClick(account.labels.email, source);

    try {
      await actions.createAccount({
        username: email?.trim?.(),
        password,
        firstName: name,
        birth: birthYear,
        gender: gender === null ? '' : gender,
        source,
        params: getPartnerParams(isPartner, location?.query),
        viewTypeData,
        recaptchaToken,
      });

      form.restart();
    } catch (error) {
      const responseData = error.response?.data;
      switch (responseData?.faultCode) {
        case FAULTCODE_USERNAME_EXISTS:
          return baseError(EMAIL_ALREADY_EXISTS, [email]);
        case FAULTCODE_BIRTH:
          return baseError(BIRTH_YEAR_TOO_YOUNG);
        default:
          return baseError(SIGN_UP_FAILED);
      }
    }
  };

  handleShowPasswordClick(event) {
    event.preventDefault();
    this.setState({ showPassword: !this.state.showPassword });
  }

  renderPersonalInfoFields(validFieldsMap, values) {
    const { showPassword } = this.state;
    const { isPartner } = this.props;
    const { getLocalizedText } = this.context;

    if (isPartner) {
      const additionalFieldProps = {
        ...inputMappedPartnerAuthStyles,
        floatingLabel: true,
        localizeErrorMessages: true,
        inlineErrors: true,
      };

      return (
        <div className={authCss.section}>
          <div
            className={classNames(authCss.fieldsSection, authCss.isPartnerFlow)}
          >
            <div className="row">
              <div className="col-xs-12">
                <Field
                  component={Input}
                  className={classNames(
                    authCss.inputField,
                    'emailField',
                    authCss.isPartnerFlow,
                  )}
                  dataTestId="email"
                  name="email"
                  id="suEmail"
                  hintText=""
                  autoCorrect="off"
                  autoCapitalize="none"
                  label={getLocalizedText(EMAIL_ADDRESS)}
                  isRequired
                  validate={composeValidators(
                    isRequired(EMAIL_REQUIRED),
                    isValidEmail,
                  )}
                  {...additionalFieldProps}
                />
                {validFieldsMap?.email && <Checkmark {...svgProps} />}
              </div>
            </div>
          </div>
          <div
            className={classNames(authCss.fieldsSection, authCss.isPartnerFlow)}
          >
            <div className="row">
              <div className="col-xs-12">
                <Field
                  component={Input}
                  className={classNames(
                    authCss.inputField,
                    'passwordField',
                    authCss.isPartnerFlow,
                  )}
                  dataTestId="password"
                  type="password"
                  name="password"
                  id="suPassword"
                  hintText=""
                  label={getLocalizedText(PASSWORD)}
                  isRequired
                  validate={composeValidators(
                    isRequired(PASSWORD_REQUIRED),
                    isValidPassword,
                  )}
                  {...additionalFieldProps}
                  inputStyle={{
                    ...inputMappedPartnerAuthStyles.inputStyle,
                    paddingBottom: '5px',
                  }}
                />
                {validFieldsMap?.password && <Checkmark {...svgProps} />}
              </div>
            </div>
          </div>
          <div
            className={classNames(authCss.fieldsSection, authCss.isPartnerFlow)}
          >
            <div className="row">
              <div className="col-xs-12">
                <Field
                  component={Input}
                  hintStyle={hintStyle}
                  hintText=""
                  className={classNames(
                    authCss.inputField,
                    'birthYearField',
                    authCss.isPartnerFlow,
                  )}
                  dataTestId="birthYear"
                  name="birthYear"
                  id="suBirthYear"
                  label={getLocalizedText(BIRTH_YEAR)}
                  isRequired
                  validate={composeValidators(
                    isRequired(BIRTH_YEAR_REQUIRED),
                    isValidBirthYear,
                  )}
                  parse={numericInputNormalizer(4)}
                  {...additionalFieldProps}
                />
                {validFieldsMap?.birthYear && <Checkmark {...svgProps} />}
              </div>
            </div>
          </div>
        </div>
      );
    }

    return (
      <div
        className={classNames(authCss.section, {
          [authCss.noSpacing]: !isPartner,
        })}
      >
        <div className={authCss.fieldsSection}>
          <div className="row">
            <div className="col-xs-12 col-sm-6">
              <Field
                component={Input}
                hintStyle={hintStyle}
                className={classNames(authCss.inputField, 'nameField')}
                dataTestId="name"
                name="name"
                id="suName"
                validate={isValidFullName}
                hintText={getLocalizedText(NAME)}
                {...inputStyles}
              />
            </div>
            <div className="col-xs-12 col-sm-6">
              <Field
                component={Input}
                className={classNames(authCss.inputField, 'emailField')}
                hintStyle={hintStyle}
                dataTestId="email"
                hintText={getLocalizedText(EMAIL_ADDRESS)}
                name="email"
                id="suEmail"
                autoCorrect="off"
                autoCapitalize="none"
                isRequired
                validate={composeValidators(
                  isRequired(EMAIL_REQUIRED),
                  isValidEmail,
                )}
                {...inputStyles}
              />
            </div>
          </div>
        </div>
        <div className={authCss.fieldsSection}>
          <div className="row">
            <div className="col-xs-12 col-sm-6">
              <Field
                component={Input}
                className={classNames(
                  authCss.inputField,
                  'passwordField',
                  authCss.alongWithIcon,
                )}
                hintStyle={hintStyle}
                hintText={getLocalizedText(PASSWORD)}
                dataTestId="password"
                type={showPassword ? 'text' : 'password'}
                name="password"
                id="suPassword"
                isRequired
                validate={composeValidators(
                  isRequired(PASSWORD_REQUIRED),
                  isValidPassword,
                )}
                {...inputStyles}
              />
              {!isPartner && values?.password && (
                <ShowPasswordButton
                  showPassword={showPassword}
                  onClick={this.handleShowPasswordClick}
                />
              )}
            </div>
            <div className="col-xs-12 col-sm-6">
              <Field
                component={Input}
                hintStyle={hintStyle}
                className={classNames(authCss.inputField, 'birthYearField')}
                dataTestId="birthYear"
                name="birthYear"
                id="suBirthYear"
                hintText={getLocalizedText(BIRTH_YEAR)}
                isRequired
                validate={composeValidators(
                  isRequired(BIRTH_YEAR_REQUIRED),
                  isValidBirthYear,
                )}
                parse={numericInputNormalizer(4)}
                {...inputStyles}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderRadioButtons() {
    const { isPartner } = this.props;
    const { getLocalizedText } = this.context;

    if (isPartner) {
      return (
        <div
          className={classNames(
            authCss.section,
            authCss.radioButtonsContainer,
            authCss.isPartnerFlow,
          )}
        >
          <div className="row">
            <div className="col-xs-12">
              <div className={classNames(css.signUpOptions, css.isPartnerFlow)}>
                <Field
                  name="gender"
                  type="radio"
                  dataTestId="genderMale"
                  isPartner
                  value="m"
                  label={getLocalizedText(MALE_LABEL)}
                  render={RadioButtonWrapper}
                />
                <Field
                  name="gender"
                  type="radio"
                  dataTestId="genderFemale"
                  isPartner
                  value="f"
                  label={getLocalizedText(FEMALE_LABEL)}
                  render={RadioButtonWrapper}
                />
                <Field
                  name="gender"
                  type="radio"
                  dataTestId="genderNonBinary"
                  isPartner
                  value="x"
                  label={getLocalizedText(NONBINARY_LABEL)}
                  render={RadioButtonWrapper}
                />
                <Field
                  name="gender"
                  type="radio"
                  dataTestId="genderPreferNotToSay"
                  isPartner
                  value="u"
                  label={getLocalizedText(PREFERNOTTOSAY_LABEL)}
                  render={RadioButtonWrapper}
                />
              </div>
            </div>
          </div>
        </div>
      );
    }

    return (
      <div
        className={classNames(authCss.section, authCss.radioButtonsContainer)}
      >
        <div className={classNames(css.signUpOptions)}>
          <Field
            name="gender"
            type="radio"
            dataTestId="genderMale"
            value="m"
            label={getLocalizedText(MALE_LABEL)}
            render={RadioButtonWrapper}
          />
          <Field
            name="gender"
            type="radio"
            dataTestId="genderFemale"
            value="f"
            label={getLocalizedText(FEMALE_LABEL)}
            render={RadioButtonWrapper}
          />
          <Field
            name="gender"
            type="radio"
            dataTestId="genderNonBinary"
            value="x"
            label={getLocalizedText(NONBINARY_LABEL)}
            render={RadioButtonWrapper}
          />
          <Field
            name="gender"
            type="radio"
            dataTestId="genderPreferNotToSay"
            value="u"
            label={getLocalizedText(PREFERNOTTOSAY_LABEL)}
            render={RadioButtonWrapper}
          />
        </div>
      </div>
    );
  }

  getSignUpButton = ({
    pristine,
    submitting,
    submitSucceeded,
    hasValidationErrors,
  }) => {
    const { isPartner, isPaymentFlow } = this.props;
    const { getLocalizedText } = this.context;

    const SignUpButtonElement = isPartner ? PremiumGoldButton : InkButton;
    const isSubmitDisabled =
      pristine || submitting || submitSucceeded || hasValidationErrors;

    return (
      <SignUpButtonElement
        id="signUpButton"
        type="submit"
        label={getLocalizedText(isPaymentFlow ? NEXT : SIGN_UP)}
        isDisabled={isSubmitDisabled}
        className={classNames(authCss.authButton, {
          [authCss.isPartnerFlow]: isPartner,
        })}
      />
    );
  };

  renderTopLevelErrorContainer(pristine, touched, submitErrors, errors) {
    const { isPartner } = this.props;

    return (
      <TopLevelErrorContainer
        className={classNames(authCss.largeSection, {
          [authCss.noSpacing]: !isPartner,
        })}
        customErrorListClass={classNames(authCss.customErrorListClass, {
          [authCss.isPartnerFlow]: isPartner,
        })}
        errorTextClassName={classNames(authCss.customErrorTextClass, {
          [authCss.isPartnerFlow]: isPartner,
        })}
        errorMessages={
          !pristine || touched ? { ...errors, ...submitErrors } : {}
        }
        isPartner={isPartner}
        fieldOrder={fieldOrder}
        touchedFields={touched}
      />
    );
  }

  render() {
    const {
      handleChangeToSignIn,
      failAuthNotice,
      queryProduct,
      isDialog,
      isPartner,
      source,
      isPaymentFlow,
      isEmailSignUpEnabled,
    } = this.props;

    const { location } = this.context;
    const isOnAirFlow = source === SOURCE_ONAIR;
    const showEmailSignUp = isPartner || isEmailSignUpEnabled || isOnAirFlow;

    return (
      <Form onSubmit={this.onSubmit}>
        {({
          handleSubmit,
          errors,
          pristine,
          touched,
          hasValidationErrors,
          submitting,
          submitSucceeded,
          submitErrors,
          values,
        }) => {
          const validFieldsMap = isPartner
            ? checkFieldsForValidInput(touched, {
                ...errors,
                ...submitErrors,
              })
            : null;

          return (
            <form onSubmit={handleSubmit}>
              <div
                data-testid="signUp"
                className={authCss.containerRestrictions}
              >
                {!isOnAirFlow && (
                  <OauthButtons
                    actionHandler={this.handleOAuthSignUp}
                    loggingHandler={this.props.logSignUpClick}
                    isDialog={isDialog}
                    isSignUp
                    isPaymentFlow={isPaymentFlow}
                    isPartner={isPartner}
                    isEmailSignUpEnabled={isEmailSignUpEnabled}
                    source={source}
                    partnerParams={getPartnerParams(isPartner, location?.query)}
                  />
                )}
                {isPartner &&
                  this.renderTopLevelErrorContainer(
                    pristine,
                    touched,
                    submitErrors,
                    errors,
                  )}
                <AuthSectionDivider isPartner={isPartner} />
                <GeneralNotices
                  key="signInGeneralNotices"
                  failAuthNotice={failAuthNotice}
                />
                {!isPartner &&
                  this.renderTopLevelErrorContainer(
                    pristine,
                    touched,
                    submitErrors,
                    errors,
                  )}
                {showEmailSignUp && (
                  <>
                    {this.renderPersonalInfoFields(validFieldsMap, values)}
                    {this.renderRadioButtons()}
                  </>
                )}
                <div className={authCss.section}>
                  <div className="row">
                    <div className="col-xs-12">
                      {showEmailSignUp &&
                        this.getSignUpButton({
                          pristine,
                          hasValidationErrors,
                          submitting,
                          submitSucceeded,
                        })}
                      <SecondaryContent
                        key="signUpSecondaryContent"
                        isPartnerFlow={isPartner}
                        handler={handleChangeToSignIn}
                      />
                      {isPartner && !!queryProduct?.freeTrialLength && (
                        <AuthFlowPromoOfferFinePrint
                          containerClassName={authCss.finePrintStyle}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </form>
          );
        }}
      </Form>
    );
  }
}

export function mapStateToProps(state) {
  return {
    isEmailSignUpEnabled: selectExperiment(
      state,
      AUTH_EMAIL_SIGNUP_ENABLED,
      true,
    ),
    isRecaptchaV3Enabled: selectExperiment(
      state,
      AUTH_EMAIL_SIGNUP_RECAPTCHA_ENABLED,
      false,
    ),
  };
}

const mapDispatchToProps = {
  logSignUpClick,
  logClientError,
};

export default connect(mapStateToProps, mapDispatchToProps)(SignUpView);
