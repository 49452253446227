import classNames from 'clsx';
import PropTypes from 'prop-types';
import { useContext } from 'react';

import {
  MODAL_SECTION_DIVIDER_TEXT,
  SECTION_DIVIDER_TEXT,
} from '../../../constants/localizations/auth';
import { LocationAndLocalizationContext } from '../../../providers/LocationAndLocalizationProvider';

import css from './auth-section-divider.module.scss';

const AuthSectionDivider = ({ isPartner }) => {
  const { getLocalizedText } = useContext(LocationAndLocalizationContext);

  if (isPartner) {
    return (
      <div
        data-testid="AuthSectionDividerContainer"
        className={css.dividerContainer}
      >
        <div className={css.dividerBar} />
        <div className={classNames(css.dividerText, css.isPartnerFlow)}>
          {getLocalizedText(SECTION_DIVIDER_TEXT)}
        </div>
        <div className={css.dividerBar} />
      </div>
    );
  }

  return (
    <p data-testid="AuthModalSectionDividerText" className={css.dividerText}>
      {getLocalizedText(MODAL_SECTION_DIVIDER_TEXT)}
    </p>
  );
};

AuthSectionDivider.propTypes = {
  isPartner: PropTypes.bool,
};

AuthSectionDivider.defaultProps = {
  isPartner: false,
};

export default AuthSectionDivider;
