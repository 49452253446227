import classnames from 'clsx';
import interpolateComponents from 'interpolate-components';
import PropTypes from 'prop-types';
import { useContext } from 'react';
import { useSelector } from 'react-redux';

import { LocationAndLocalizationContext } from '../../../providers/LocationAndLocalizationProvider';
import { selectTermsOfServiceLink } from '../../../selectors/config';
import UnderlineLink from '../../shared/link/UnderlineLink';
import css from './auth-view-footer.module.scss';

const AuthViewFooter = ({ agreementText, isPartnerFlow, source }) => {
  const { getLocalizedText } = useContext(LocationAndLocalizationContext);
  const termsOfServiceLink = useSelector(selectTermsOfServiceLink);

  return (
    <div
      data-testid="authViewFooter"
      className={classnames(css.container, {
        [css.isPartnerFlow]: isPartnerFlow,
      })}
    >
      <p
        data-testid="authAgreement"
        className={classnames(css.agreementLine, {
          [css.isPartnerFlow]: isPartnerFlow,
        })}
      >
        {interpolateComponents({
          mixedString: getLocalizedText(agreementText),
          components: {
            a1: (
              <UnderlineLink
                data-testid="policyLink"
                underlineClassName={classnames(
                  css.legalLink,
                  css.serviceTerms,
                  {
                    [css.isPartnerFlow]: isPartnerFlow,
                  },
                )}
                className={classnames(css.legalLink, {
                  [css.isPartnerFlow]: isPartnerFlow,
                })}
                to={termsOfServiceLink}
                target="_blank"
                isNotReactRouterLink
                isDropDown
              />
            ),
            a2: (
              <UnderlineLink
                data-testid="privacyLink"
                underlineClassName={classnames(
                  css.legalLink,
                  css.privacyPolicy,
                  {
                    [css.isPartnerFlow]: isPartnerFlow,
                  },
                )}
                className={classnames(css.legalLink, {
                  [css.isPartnerFlow]: isPartnerFlow,
                })}
                to="https://tunein.com/policies/privacy/"
                target="_blank"
                isNotReactRouterLink
                isDropDown
              />
            ),
            a3: (
              <UnderlineLink
                data-testid={`${source}Link`}
                className={classnames(css.legalLink, {
                  [css.isPartnerFlow]: isPartnerFlow,
                })}
                to={`https://tunein.com/policies/${source}/`}
                target="_blank"
                isNotReactRouterLink
                isDropDown
              />
            ),
          },
        })}
      </p>
    </div>
  );
};

AuthViewFooter.propTypes = {
  agreementText: PropTypes.string.isRequired,
  isPartnerFlow: PropTypes.bool,
  source: PropTypes.string,
};

export default AuthViewFooter;
