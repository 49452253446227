import classNames from 'clsx';
import PropTypes from 'prop-types';
import { isValidElement } from 'react';

import PaymentDisclaimer from '../../payment/PaymentDisclaimer';
import AuthFlowPromoHeader from './AuthFlowPromoHeader';

import css from './auth-flow-layout.module.scss';

const AuthFlowLayout = ({
  headerFromProps,
  hideFooter,
  hideHeaderText,
  logoContainerClassName,
  useFullTuneInLogo,
  exaggerateMarginTop,
  children,
}) => {
  const header = isValidElement(headerFromProps) ? (
    headerFromProps
  ) : (
    <AuthFlowPromoHeader
      hideHeaderText={hideHeaderText}
      logoContainerClassName={logoContainerClassName}
      useFullTuneInLogo={useFullTuneInLogo}
      exaggerateMarginTop={exaggerateMarginTop}
    />
  );

  return (
    <div
      data-testid="authFlowLayoutContainer"
      className={css.authFlowLayoutContainer}
    >
      <div
        data-testid="authFlowLayoutContent"
        className={css.authFlowLayoutContent}
      >
        <div className={css.authFlowLayoutSharedStyles}>{header}</div>
        <div className={css.authFlowLayoutSharedStyles}>{children}</div>
        {!hideFooter && (
          <div
            className={classNames(
              css.authFlowLayoutSharedStyles,
              css.authFlowLayoutFooter,
            )}
          >
            <PaymentDisclaimer isPartnerOrPairingFlow />
          </div>
        )}
      </div>
    </div>
  );
};

AuthFlowLayout.propTypes = {
  headerFromProps: PropTypes.object,
  logoContainerClassName: PropTypes.string,
  useFullTuneInLogo: PropTypes.bool,
  exaggerateMarginTop: PropTypes.bool,
  hideFooter: PropTypes.bool,
  hideHeaderText: PropTypes.bool,
  children: PropTypes.object.isRequired,
};

export default AuthFlowLayout;
