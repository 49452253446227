import get from 'lodash/get';
import PropTypes from 'prop-types';
import qs from 'qs';
import { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import accountCategory from 'src/common/constants/analytics/categoryActionLabel/account';
import subscribeCategory from 'src/common/constants/analytics/categoryActionLabel/subscribe';
import {
  FORGOT_PASSWORD_TITLE,
  RESET_PASSWORD_TITLE,
  SIGN_IN,
  SIGN_UP,
} from 'src/common/constants/localizations/auth';
import { getOauthPartnerId } from 'src/common/utils/queryParams';
import { updateAuthViewType } from '../../actions/auth';
import { logCategoryActionLabel } from '../../actions/logging';
import viewTypes from '../../constants/auth/viewTypes';
import { LocationAndLocalizationContext } from '../../providers/LocationAndLocalizationProvider';
import generateOriginLabelFromQuery from '../../utils/generateOriginLabelFromQuery';
import parseQuery from '../../utils/queryString/parse';
import changeRedirectIfNedeed from './utils/changeRedirectIfNedeed';

const typeTitleMap = {
  [viewTypes.signIn]: SIGN_IN,
  [viewTypes.signUp]: SIGN_UP,
  [viewTypes.forgotPassword]: FORGOT_PASSWORD_TITLE,
  [viewTypes.resetPassword]: RESET_PASSWORD_TITLE,
  [viewTypes.verify]: SIGN_IN,
};

const viewTypeLoggingLabelMap = {
  [viewTypes.signIn]: accountCategory.labels.signIn,
  [viewTypes.signUp]: accountCategory.labels.signUp,
  [viewTypes.verify]: accountCategory.labels.verify,
};

const sourceLoggingCategoryMap = {
  [subscribeCategory.labels.fullPageUpsell]: subscribeCategory.category,
  [subscribeCategory.labels.profileUpsell]: subscribeCategory.category,
  [subscribeCategory.labels.directBilling]: subscribeCategory.category,
};

/*
  withAuthViewHandling is a decorator that wraps components that use AuthViewContainer.js

  The purpose of this component is to centralize all of the viewtype logic in one place.
  and then pass down as props: the new viewtype, updated pageTitle and updated source.

  logging the show event for the auth view is also done here.
*/
export default function withAuthViewHandling(options = {}) {
  const {
    isFullPage, // AuthPage passes true to force page navigation
    isPartner, // OauthAuthPage passes true to force appropriate logging
  } = options;

  return (WrappedComponent) => {
    class WithAuthViewHandling extends Component {
      static propTypes = {
        history: PropTypes.object.isRequired,
        loginDetails: PropTypes.shape({
          viewType: PropTypes.string,
          authDialogOpen: PropTypes.bool.isRequired,
        }),
        routeProps: PropTypes.object.isRequired,
        actions: PropTypes.object.isRequired,
      };

      static contextType = LocationAndLocalizationContext;

      constructor(props, context) {
        super(props, context);

        const startingViewType =
          parseQuery(context.location.search).vt || // in query/link, if page is reloaded
          get(props.routeProps, 'auth.viewType') || // route level
          props.loginDetails.viewType || // default for auth dialog
          viewTypes.signIn; // fallback

        // as viewType is initialized as 'si' in store,
        // update viewType in store if viewType in query is 'su' on page load
        if (startingViewType !== props.loginDetails.viewType) {
          props.actions.updateAuthViewType(startingViewType);
        }

        this.state = {
          viewType: startingViewType,
          label: viewTypeLoggingLabelMap[startingViewType],
          pageTitle: this.getLocalizedTitle(startingViewType),
          source: generateOriginLabelFromQuery(context.location),
        };

        this.getLocalizedTitle = this.getLocalizedTitle.bind(this);
        this.handleViewChange = this.handleViewChange.bind(this);
        this.logShowEvent = this.logShowEvent.bind(this);
      }

      componentDidMount() {
        if (isFullPage) {
          // initial show event for a full page
          this.logShowEvent();
        }
      }

      componentDidUpdate(prevProps) {
        // Perform reporting only if view has changed
        const { loginDetails } = this.props;

        const isAuthDialogOpen =
          !prevProps.loginDetails.authDialogOpen && loginDetails.authDialogOpen;

        if (isAuthDialogOpen || loginDetails.viewType !== this.state.viewType) {
          this.handleViewChange(loginDetails.viewType);
        }
      }

      getLocalizedTitle(viewType) {
        const titleKey = typeTitleMap[viewType];
        return this.context.getLocalizedText(titleKey);
      }

      handleViewChange(viewType) {
        const { location } = this.context;

        this.setState(
          {
            viewType,
            label: viewTypeLoggingLabelMap[viewType],
            pageTitle: this.getLocalizedTitle(viewType),
          },
          this.logShowEvent,
        );

        if (isFullPage) {
          const updatedRedirect = changeRedirectIfNedeed(viewType, location);
          const queryParams = {
            ...location.query,
            vt: viewType,
            ...(updatedRedirect && {
              redirect: updatedRedirect,
            }),
          };

          this.props.history.push({
            pathname: location.pathname,
            query: queryParams,
            search: `?${qs.stringify(queryParams)}`,
          });
        }
      }

      logShowEvent() {
        const { location } = this.context;
        const { actions } = this.props;
        const { source, label } = this.state;

        const categoryToLog =
          sourceLoggingCategoryMap[source] || accountCategory.category;

        // label only exists for sign in / sign up
        if (label) {
          let labelWithSource = source ? `${label}.${source}` : label;
          if (isPartner) {
            const partnerId = getOauthPartnerId(location?.query);
            labelWithSource = `${labelWithSource}.${accountCategory.labels.partner}.${partnerId}`;
          }

          actions.logCategoryActionLabel({
            category: categoryToLog, // pass in when invoking the decorator
            action: accountCategory.actions.show,
            label: labelWithSource,
          });
        }
      }

      render() {
        const { source, viewType, pageTitle } = this.state;

        return (
          <WrappedComponent
            {...this.props}
            viewType={viewType}
            source={source}
            pageTitle={pageTitle}
          />
        );
      }
    }

    function mapStateToProps(state) {
      return {
        loginDetails: state.loginDetails,
      };
    }

    function mapDispatchToProps(dispatch) {
      return {
        actions: bindActionCreators(
          { logCategoryActionLabel, updateAuthViewType },
          dispatch,
        ),
      };
    }

    return connect(mapStateToProps, mapDispatchToProps)(WithAuthViewHandling);
  };
}
