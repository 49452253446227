import classNames from 'clsx';
import interpolateComponents from 'interpolate-components';
import { useContext } from 'react';
import { NEED_HELP } from 'src/common/constants/localizations/auth';
import { LocationAndLocalizationContext } from '../../../providers/LocationAndLocalizationProvider';
import UnderlineLink from '../../shared/link/UnderlineLink';
import authCss from '../auth.module.scss';

const NeedHelp = () => {
  const { getLocalizedText } = useContext(LocationAndLocalizationContext);

  return (
    <p data-testid="needHelp" key="needHelp" className={authCss.infoText}>
      {interpolateComponents({
        mixedString: getLocalizedText(NEED_HELP),
        components: {
          a: (
            <UnderlineLink
              role="button"
              tabIndex="0"
              key="emailLink"
              className={classNames(authCss.link, authCss.needHelpInlineLink)}
              to="mailto:support@tunein.com"
              isDropDown
              isNotReactRouterLink
            />
          ),
        },
      })}
    </p>
  );
};

export default NeedHelp;
