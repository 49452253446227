import classNames from 'clsx';
import PropTypes from 'prop-types';
import {
  fbPixelCustomEvents,
  fbTrackOauthLogin,
} from 'src/client/analytics/facebook/fbTrack';
import account from '../../../constants/analytics/categoryActionLabel/account';
import {
  CONTINUE_WITH_APPLE,
  CONTINUE_WITH_FACEBOOK,
  CONTINUE_WITH_GOOGLE,
} from '../../../constants/localizations/auth';
import AppleSignInButton from '../oauth/AppleSignInButton';
import FacebookButton from '../oauth/FacebookButton';
import GoogleButton from '../oauth/GoogleButton';
import css from './oauth-buttons.module.scss';

const OauthButtons = ({
  actionHandler,
  loggingHandler,
  isDialog,
  isSignIn,
  isSignUp,
  isPartner,
  source,
  partnerParams,
  isPaymentFlow,
  isEmailSignUpEnabled,
}) => (
  <div data-testid="oauthButtons">
    <div
      className={classNames(css.container, css.threeButtonLayout, {
        [css.fullScreen]: !isDialog,
        [css.paymentFlowLayout]: isPaymentFlow,
        [css.isPartnerFlow]: isPartner,
        [css.smallBottomMargin]: !isEmailSignUpEnabled,
      })}
    >
      <div className={css.oauthButton}>
        <GoogleButton
          buttonText={CONTINUE_WITH_GOOGLE}
          partnerParams={partnerParams}
          source={source}
          actionHandler={actionHandler}
          isSignIn={isSignIn}
          isPartner={isPartner}
          onClick={() => {
            fbTrackOauthLogin(
              isSignIn,
              isSignUp,
              fbPixelCustomEvents.SignUpWithGoogle,
            );
            loggingHandler(account.labels.google, source);
          }}
        />
      </div>
      <div className={css.oauthButton}>
        <FacebookButton
          buttonText={CONTINUE_WITH_FACEBOOK}
          partnerParams={partnerParams}
          source={source}
          actionHandler={actionHandler}
          isSignIn={isSignIn}
          isPartner={isPartner}
          onClick={() => {
            fbTrackOauthLogin(
              isSignIn,
              isSignUp,
              fbPixelCustomEvents.SignUpWithFacebook,
            );
            loggingHandler(account.labels.facebook, source);
          }}
        />
      </div>
      <div className={css.oauthButton}>
        <AppleSignInButton
          buttonText={CONTINUE_WITH_APPLE}
          partnerParams={partnerParams}
          source={source}
          actionHandler={actionHandler}
          isPartner={isPartner}
          onClick={() => {
            fbTrackOauthLogin(
              isSignIn,
              isSignUp,
              fbPixelCustomEvents.SignUpWithApple,
            );
            loggingHandler(account.labels.apple, source);
          }}
        />
      </div>
    </div>
  </div>
);

OauthButtons.propTypes = {
  actionHandler: PropTypes.func.isRequired,
  loggingHandler: PropTypes.func.isRequired,
  isDialog: PropTypes.bool,
  isSignUp: PropTypes.bool,
  isSignIn: PropTypes.bool,
  isPartner: PropTypes.bool,
  partnerParams: PropTypes.object,
  source: PropTypes.string,
  isPaymentFlow: PropTypes.bool,
  isEmailSignUpEnabled: PropTypes.bool,
};

export default OauthButtons;
