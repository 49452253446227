import classNames from 'clsx';
import flow from 'lodash/flow';
import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import { Component, createRef } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { updateAuthViewType as triggerAuthViewChange } from 'src/common/actions/auth';
import {
  AUTH_PAIRING_FLOW_TI_CC_FLOW_HEADER,
  AUTH_PAIRING_FLOW_TI_FOR_FREE_HEADER,
  AUTH_SIGN_IN_TITLE,
  AUTH_SIGN_UP_TITLE,
  FORGOT_PASSWORD_INSTRUCTIONS,
  FORGOT_PASSWORD_TITLE,
  RESET_PASSWORD_INSTRUCTIONS,
  RESET_PASSWORD_TITLE,
  SIGN_IN,
  SIGN_IN_AGREEMENT,
  SIGN_IN_SIGN_UP_SUBTITLE,
  SIGN_IN_SIGN_UP_TITLE,
  SIGN_UP_AGREEMENT,
  VERIFY_SUBTITLE,
} from 'src/common/constants/localizations/auth';
import { selectIsMobile } from 'src/common/selectors/app';
import { logAccountActivity } from '../../actions/logging';
import viewTypes, { viewTypeAttributes } from '../../constants/auth/viewTypes';
import { PAYMENT_FLOW } from '../../constants/subscription/flow';
import { LocationAndLocalizationContext } from '../../providers/LocationAndLocalizationProvider';
import getOverrideOrDefaultResourceKey from '../../utils/getOverrideOrDefaultResourceKey';
import PaymentFlowFormLayout from '../payment/PaymentFlowFormLayout';
import AuthAndPaymentFlowHeader from '../shared/AuthAndPaymentFlowHeader';
import isPaymentRequired from '../utils/isPaymentRequired';
import ForgotPassword from './ForgotPassword';
import ResetPassword from './ResetPassword';
import SignInView from './SignInView';
import SignUpView from './SignUpView';
import css from './auth-view-container.module.scss';
import authCss from './auth.module.scss';
import AuthFlowLayout from './shared/AuthFlowLayout';
import AuthViewFooter from './shared/AuthViewFooter';
import LogoutFailure from './shared/LogoutFailure';

/*
  The AuthViewContainer is used in OauthAuthPage.js AuthPage, and AuthDialog.js
*/
class AuthViewContainer extends Component {
  static propTypes = {
    isMobile: PropTypes.bool.isRequired,
    updateAuthViewType: PropTypes.func.isRequired,
    isDialog: PropTypes.bool,
    isPartner: PropTypes.bool,
    isPairingFlow: PropTypes.bool,
    queryProduct: PropTypes.object,
    formContainerClassName: PropTypes.string,
    resetFormPadding: PropTypes.bool,
    authActions: PropTypes.object.isRequired,
    logActivity: PropTypes.func.isRequired,
    // from withAuthViewHandling
    history: PropTypes.object.isRequired,
    loginDetails: PropTypes.object.isRequired,
    source: PropTypes.string,
    viewType: PropTypes.string.isRequired,
  };

  static contextType = LocationAndLocalizationContext;

  static defaultProps = {
    formContainerClassName: '',
  };

  constructor(props) {
    super(props);

    this.authViewContainerRef = createRef();
  }

  componentDidMount() {
    // Always apply focus to the container, for keyboard accessibility. However, don't do this for
    // mobile devices
    if (!this.props.isMobile) {
      this.authViewContainerRef.current.focus();
    }
  }

  getViewToRender() {
    const {
      authActions,
      loginDetails,
      isDialog,
      isPartner,
      isPairingFlow,
      history,
      queryProduct,
      viewType,
      source,
      updateAuthViewType,
      formContainerClassName,
      resetFormPadding = false,
      logActivity,
    } = this.props;
    const { getLocalizedText, location } = this.context;
    const requiresPayment = isPaymentRequired(queryProduct, location);

    // We don't want to render the payment flow layout for an auth view if it is
    // in a dialog.
    const isPaymentFlow = !!location.query[PAYMENT_FLOW] && !isDialog;

    const commonProps = {
      actions: authActions,
      failAuthNotice: loginDetails?.failAuthNotice,
      successAuthNotice: loginDetails?.successAuthNotice,
      isDialog,
      history,
      isPaymentFlow,
      viewTypeData: {
        viewType,
        attribute: isDialog
          ? viewTypeAttributes.dialog
          : viewTypeAttributes.fullPage,
      },
    };

    const layoutProps = {
      alwaysShowUpsellTable: true,
      showProgressBar: [viewTypes.signIn, viewTypes.signUp].includes(viewType),
      selectedProduct: queryProduct,
      isPaymentFlow,
      isPartnerFlow: isPartner,
      isNoPaymentFlow: !requiresPayment,
      formContainerClassName,
      resetFormPadding,
    };

    // The isPartner flow uses a layout that handles it's own footer
    const shouldShowAuthFooter = !isPartner;

    const generateSignInSignUpViewContent = (mainContent) => {
      const isSignUpPage = viewType === viewTypes.signUp;
      // check all instances of requiresBillingInfo...
      const pairingFlowWithNoPaymentSku =
        isPairingFlow &&
        !isEmpty(queryProduct) &&
        !queryProduct?.requiresBillingInfo;
      const partnerFlowHeaderKey = pairingFlowWithNoPaymentSku
        ? AUTH_PAIRING_FLOW_TI_FOR_FREE_HEADER // No Payment SKU messaging
        : AUTH_PAIRING_FLOW_TI_CC_FLOW_HEADER; // Default Partner / Pairing Flow messaging
      let defaultHeaderKey = isSignUpPage
        ? AUTH_SIGN_UP_TITLE
        : AUTH_SIGN_IN_TITLE;
      defaultHeaderKey = isPaymentFlow
        ? defaultHeaderKey
        : SIGN_IN_SIGN_UP_TITLE;

      return (
        <>
          <AuthAndPaymentFlowHeader
            isPartnerFlow={isPartner}
            isPairingFlow={isPairingFlow}
            header={
              isPartner
                ? partnerFlowHeaderKey
                : getOverrideOrDefaultResourceKey(
                    getLocalizedText,
                    location,
                    defaultHeaderKey,
                  )
            }
            subheader={
              isPartner
                ? ''
                : getOverrideOrDefaultResourceKey(
                    getLocalizedText,
                    location,
                    SIGN_IN_SIGN_UP_SUBTITLE,
                  )
            }
            isSignInSignUp
          />
          {mainContent}
          {shouldShowAuthFooter && (
            <AuthViewFooter
              agreementText={
                isSignUpPage
                  ? getOverrideOrDefaultResourceKey(
                      getLocalizedText,
                      location,
                      SIGN_UP_AGREEMENT,
                    )
                  : SIGN_IN_AGREEMENT
              }
              source={source}
            />
          )}
        </>
      );
    };

    switch (viewType) {
      case viewTypes.signUp: {
        const signUpView = (
          <SignUpView
            key="signUpView"
            queryProduct={queryProduct}
            handleChangeToSignIn={() => updateAuthViewType(viewTypes.signIn)}
            isPartner={isPartner}
            source={source}
            {...commonProps}
          />
        );
        const generatedViewContent =
          generateSignInSignUpViewContent(signUpView);

        return isPartner ? (
          <AuthFlowLayout>
            <div className={css.partnerAuthContent}>{generatedViewContent}</div>
          </AuthFlowLayout>
        ) : (
          <PaymentFlowFormLayout {...layoutProps}>
            <div>{generatedViewContent}</div>
          </PaymentFlowFormLayout>
        );
      }
      case viewTypes.forgotPassword:
        return (
          <PaymentFlowFormLayout {...layoutProps}>
            <div>
              <AuthAndPaymentFlowHeader
                header={FORGOT_PASSWORD_TITLE}
                subheader={FORGOT_PASSWORD_INSTRUCTIONS}
              />
              <ForgotPassword
                key="forgotPasswordView"
                handleChangeToSignIn={() =>
                  updateAuthViewType(viewTypes.signIn)
                }
                logAccountActivity={logActivity}
                {...commonProps}
              />
            </div>
          </PaymentFlowFormLayout>
        );
      case viewTypes.resetPassword:
        return (
          <PaymentFlowFormLayout {...layoutProps}>
            <div>
              <AuthAndPaymentFlowHeader
                header={RESET_PASSWORD_TITLE}
                subheader={RESET_PASSWORD_INSTRUCTIONS}
              />
              <ResetPassword
                key="forgotPasswordView"
                handleChangeToSignIn={() =>
                  updateAuthViewType(viewTypes.signIn)
                }
                {...commonProps}
              />
            </div>
          </PaymentFlowFormLayout>
        );
      case viewTypes.logoutFailure:
        return <LogoutFailure key="logoutFailureView" />;
      case viewTypes.verify:
        return (
          <PaymentFlowFormLayout {...layoutProps}>
            <div>
              <AuthAndPaymentFlowHeader
                header={SIGN_IN}
                subheader={VERIFY_SUBTITLE}
              />
              <SignInView
                key="signInView"
                source={source}
                isVerifyFlow
                {...commonProps}
              />
            </div>
          </PaymentFlowFormLayout>
        );
      default: {
        const signInView = (
          <SignInView
            key="signInView"
            queryProduct={queryProduct}
            handleChangeToSignUp={() => updateAuthViewType(viewTypes.signUp)}
            handleChangeToForgotPassword={() =>
              updateAuthViewType(viewTypes.forgotPassword)
            }
            isPartner={isPartner}
            source={source}
            {...commonProps}
          />
        );
        const generatedViewContent =
          generateSignInSignUpViewContent(signInView);

        return isPartner ? (
          <AuthFlowLayout>
            <div className={css.partnerAuthContent}>{generatedViewContent}</div>
          </AuthFlowLayout>
        ) : (
          <PaymentFlowFormLayout {...layoutProps}>
            <div>{generatedViewContent}</div>
          </PaymentFlowFormLayout>
        );
      }
    }
  }

  render() {
    const { isDialog } = this.props;

    return (
      <div
        ref={this.authViewContainerRef}
        data-testid="authViewContainer"
        tabIndex="0"
        className={classNames(css.container, authCss.withFormSections, {
          [authCss.fullScreen]: !isDialog,
        })}
      >
        {this.getViewToRender()}
      </div>
    );
  }
}

export const mapStateToProps = (state) => ({
  isMobile: selectIsMobile(state),
});

function mapDispatchToProps(dispatch) {
  const actions = {
    updateAuthViewType: triggerAuthViewChange,
    logActivity: logAccountActivity,
  };
  return {
    ...bindActionCreators(actions, dispatch),
  };
}

export default flow(connect(mapStateToProps, mapDispatchToProps))(
  AuthViewContainer,
);
