import classnames from 'clsx';
import PropTypes from 'prop-types';
import { useContext } from 'react';
import { connect } from 'react-redux';

import { selectPartners } from '../../../selectors/partners';
import { selectProducts } from '../../../selectors/products';

import { LocationAndLocalizationContext } from '../../../providers/LocationAndLocalizationProvider';

import {
  AUTH_PAIRING_SUCCESS_PROMO_FREEPREMIUM,
  YOU,
} from '../../../constants/localizations/auth';
import { TUNE_IN_BADGE_LOGO } from '../../../constants/localizations/shared';

import findPartnerInfo from '../../../utils/findPartnerInfo';
import { selectProductFromQuery } from '../../../utils/subscription/findProductHelpers';
import { createFreeTrialLengthWithTrialPeriod } from '../../../utils/subscription/freeTrialPeriodHelpers';
import TuneInLogo from '../../header/TuneInLogo';

import assetUrl from '../../../utils/assetUrl';
import css from './auth-flow-promo-header.module.scss';

const tiBadgeAssetPath = 'assets/img/ti-badge-white.svg';

const AuthFlowPromoHeader = ({
  partners,
  products,
  hideHeaderText,
  logoContainerClassName,
  useFullTuneInLogo,
  exaggerateMarginTop,
}) => {
  const { getLocalizedText, location } = useContext(
    LocationAndLocalizationContext,
  );
  const partnerInfo = findPartnerInfo(location?.query, partners);
  const partnerName = partnerInfo?.displayName;

  const selectedProduct = selectProductFromQuery(products, location);
  const { freeTrialLength, isMonthlyTrial } = selectedProduct || {};
  const freeTrialLengthWithTrialPeriod = createFreeTrialLengthWithTrialPeriod(
    getLocalizedText,
    freeTrialLength,
    isMonthlyTrial,
  );
  let localizedHeaderText;

  if (freeTrialLengthWithTrialPeriod) {
    localizedHeaderText = getLocalizedText(
      AUTH_PAIRING_SUCCESS_PROMO_FREEPREMIUM,
      {
        freeTrialLengthWithTrialPeriod,
        partnerName: partnerName || getLocalizedText(YOU),
      },
    );
  }

  return (
    <div
      data-testid="authFlowPromoHeaderContainer"
      className={classnames(css.authFlowPromoHeaderContainer, {
        [css.exaggerateMarginTop]: exaggerateMarginTop,
      })}
    >
      <div
        className={classnames(
          css.authFlowPromoHeaderLogoContainer,
          logoContainerClassName,
        )}
      >
        {useFullTuneInLogo ? (
          <TuneInLogo unlinkedLogo iconProps={{ width: '100%' }} />
        ) : (
          <img
            className={css.authFlowPromoHeaderBadgeImg}
            src={assetUrl(tiBadgeAssetPath)}
            role="presentation"
            data-testid="authFlowTuneInBadgeImg"
            alt={getLocalizedText(TUNE_IN_BADGE_LOGO)}
          />
        )}
      </div>
      {!hideHeaderText && !!localizedHeaderText && (
        <p
          data-testid="authFlowPromoHeaderText"
          className={css.authFlowPromoHeaderText}
        >
          {localizedHeaderText}
        </p>
      )}
    </div>
  );
};

AuthFlowPromoHeader.propTypes = {
  partners: PropTypes.object,
  products: PropTypes.object,
  hideHeaderText: PropTypes.bool,
  logoContainerClassName: PropTypes.string,
  useFullTuneInLogo: PropTypes.bool,
  exaggerateMarginTop: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  partners: selectPartners(state),
  products: selectProducts(state),
});

export default connect(mapStateToProps)(AuthFlowPromoHeader);
