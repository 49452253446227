import {
  SUBSCRIBE_FREE_TRIAL_PERIOD_DAY,
  SUBSCRIBE_FREE_TRIAL_PERIOD_DAYS,
  SUBSCRIBE_FREE_TRIAL_PERIOD_MONTH,
  SUBSCRIBE_FREE_TRIAL_PERIOD_MONTHS,
} from 'src/common/constants/localizations/subscription';

export const freeTrialUnitTypes = {
  months: 'months',
  days: 'days',
};

export const stripeFreeTrialUnitTypes = {
  month: 'month',
  year: 'year',
  week: 'week',
  day: 'day',
};

export const freeTrialLengthWithTrialPeriodKeys = {
  months: {
    singular: SUBSCRIBE_FREE_TRIAL_PERIOD_MONTH,
    plural: SUBSCRIBE_FREE_TRIAL_PERIOD_MONTHS,
  },
  days: {
    singular: SUBSCRIBE_FREE_TRIAL_PERIOD_DAY,
    plural: SUBSCRIBE_FREE_TRIAL_PERIOD_DAYS,
  },
};
