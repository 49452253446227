import vars from '../../vars';

export const recaptchaActions = {
  emailSignUp: 'emailSignUp',
  emailSignIn: 'emailSignIn',
};
export const recaptchaSiteKey = vars.get('RECAPTCHA_V3_SITE_KEY');
export const recaptchaSecret = vars.get('RECAPTCHA_V3_SECRET_KEY');
export const recaptchaVerifyEndpoint =
  'https://www.google.com/recaptcha/api/siteverify';
