import { LOGOUT_FAILED } from 'src/common/constants/localizations/auth';
import ActionFailureAlert from '../../shared/alerts/ActionFailureAlert';

const LogoutFailure = () => (
  <div data-testid="logoutFailure">
    <ActionFailureAlert key="logoutFailure" localizedText={LOGOUT_FAILED} />
  </div>
);

export default LogoutFailure;
