import classNames from 'clsx';
import get from 'lodash/get';
import PropTypes from 'prop-types';
import { Component } from 'react';

import withStandardWindowControls from '../../../decorators/withStandardWindowControls';
import Text from '../../Text';
import PillButton from '../../shared/button/PillButton';

import config from '../../../config';
import accountCategory from '../../../constants/analytics/categoryActionLabel/account';

import iconsCss from '../../../styles/icons.module.scss';
import css from './oauth-button.module.scss';

const {
  secureOrigin,
  oauth: {
    apple: { clientUrl, messageType },
  },
} = config;

const windowConfig = {
  width: 1180,
  height: 980,
  url: clientUrl,
};

class AppleSignInButton extends Component {
  static propTypes = {
    actionHandler: PropTypes.func.isRequired,
    buttonText: PropTypes.string.isRequired,
    onClick: PropTypes.func,
    partnerParams: PropTypes.object,
    source: PropTypes.string,
    isPartner: PropTypes.bool,
    // from decorator
    openStandardWindow: PropTypes.func.isRequired,
    closeStandardWindow: PropTypes.func.isRequired,
  };

  static defaultProps = {
    partnerParams: {},
  };

  handleClick = () => {
    const { onClick, openStandardWindow } = this.props;
    if (onClick) onClick();
    openStandardWindow(windowConfig, this.handleOAuthMessage);
  };

  handleOAuthMessage = (event) => {
    const eventMessageType = get(event, 'data.type');
    const isInvalidOAuthMessage =
      event.origin !== secureOrigin || eventMessageType !== messageType;

    if (isInvalidOAuthMessage) {
      return;
    }

    const { actionHandler, source, closeStandardWindow, partnerParams } =
      this.props;
    const { accountId: userId, token, firstName, lastName } = event.data;

    closeStandardWindow();

    const data = { userId, token, firstName, lastName };

    actionHandler(accountCategory.labels.apple, data, source, partnerParams);
  };

  render() {
    const { buttonText, isPartner } = this.props;

    return (
      <PillButton
        id="appleSignInOauthButton"
        className={classNames(css.appleSignInButton, {
          [css.isPartnerFlow]: isPartner,
        })}
        label={<Text name={buttonText} />}
        icon={<i className={iconsCss['icon-apple']} />}
        onClick={this.handleClick}
      />
    );
  }
}

export default withStandardWindowControls(AppleSignInButton);
