import classNames from 'clsx';
import { FORM_ERROR } from 'final-form';
import PropTypes from 'prop-types';
import { Component } from 'react';
import { Field, Form } from 'react-final-form';
import { connect } from 'react-redux';
import {
  fbPixelCustomEvents,
  fbTrack,
  fbTrackSignIn,
} from 'src/client/analytics/facebook/fbTrack';
import {
  hintStyle,
  inputStyles,
} from 'src/common/components/shared/inputSharedStyles';
import { recaptchaActions } from 'src/common/constants/auth/recaptcha';
import { AUTH_EMAIL_SIGNIN_RECAPTCHA_ENABLED } from 'src/common/constants/experiments/auth';
import {
  FORGOT_PASSWORD_LINK,
  GENERAL_FAILURE,
  NEXT,
  REMEMBER_ME_LABEL,
  SIGN_IN,
  SIGN_IN_FAILED,
  USERNAME_EMAIL,
  USERNAME_EMAIL_REQUIRED,
} from 'src/common/constants/localizations/auth';
import {
  PASSWORD,
  PASSWORD_REQUIRED,
} from 'src/common/constants/localizations/shared';
import { SOURCE_ONAIR } from 'src/common/constants/sourceQueryParams';
import { selectExperiment } from 'src/common/selectors/config';
import { getPartnerParams } from 'src/common/utils/auth/partnerAuthFlowHelpers';
import { getRecaptchaToken } from 'src/common/utils/auth/recaptcha';
import { logClientError, logSignInClick } from '../../actions/logging';
import account from '../../constants/analytics/categoryActionLabel/account';
import { LocationAndLocalizationContext } from '../../providers/LocationAndLocalizationProvider';
import { isRequired } from '../../utils/form/validation';
import vars from '../../vars';
import InkButton from '../shared/button/InkButton';
import PremiumGoldButton from '../shared/button/PremiumGoldButton';
import ShowPasswordButton from '../shared/button/ShowPasswordButton';
import CheckBox from '../shared/form/CheckBox';
import Input from '../shared/form/Input';
import TopLevelErrorContainer from '../shared/form/TopLevelErrorContainer';
import UnderlineLink from '../shared/link/UnderlineLink';
import partnerAuthSharedStyles, {
  inputCheckmarkSvgProps,
  inputMappedPartnerAuthStyles,
} from '../shared/styles/partnerAuthSharedStyles';
import ChevronRight from '../shared/svgIcons/ChevronRight';
import Checkmark from '../shared/svgIcons/LegacyCheckmark';
import checkFieldsForValidInput from '../utils/checkFieldsForValidInput';
import authCss from './auth.module.scss';
import AuthFlowPromoOfferFinePrint from './shared/AuthFlowPromoOfferFinePrint';
import AuthSectionDivider from './shared/AuthSectionDivider';
import GeneralNotices from './shared/GeneralNotices';
import OauthButtons from './shared/OauthButtons';
import SecondaryContent from './shared/SecondaryContent';
import css from './sign-in-view.module.scss';

const fieldOrder = ['usernameEmail', 'password'];

const svgProps = {
  ...inputCheckmarkSvgProps,
  className: authCss.checkmark,
};

// exported for testing
export class SignInView extends Component {
  static propTypes = {
    actions: PropTypes.shape({
      loginWithEmail: PropTypes.func.isRequired,
      loginWithOauth: PropTypes.func.isRequired,
      verifyWithEmail: PropTypes.func.isRequired,
      verifyWithOauth: PropTypes.func.isRequired,
    }),
    logSignInClick: PropTypes.func.isRequired,
    logClientError: PropTypes.func.isRequired,
    isRecaptchaV3Enabled: PropTypes.bool,
    viewTypeData: PropTypes.object.isRequired,
    queryProduct: PropTypes.object,
    handleChangeToSignUp: PropTypes.func,
    failAuthNotice: PropTypes.string,
    successAuthNotice: PropTypes.string,
    handleChangeToForgotPassword: PropTypes.func,
    isDialog: PropTypes.bool,
    isPartner: PropTypes.bool,
    source: PropTypes.string,
    isPaymentFlow: PropTypes.bool,
    isVerifyFlow: PropTypes.bool,
  };

  static contextType = LocationAndLocalizationContext;

  constructor(props) {
    super(props);

    this.state = {
      showPassword: false,
    };

    this.onSubmit = this.onSubmit.bind(this);
    this.handleApiError = this.handleApiError.bind(this);
    this.handleOauthSignIn = this.handleOauthSignIn.bind(this);
    this.handleShowPasswordClick = this.handleShowPasswordClick.bind(this);
  }

  componentDidMount() {
    fbTrack(fbPixelCustomEvents.SignInPageVisit);
  }

  // eslint-disable-next-line consistent-return
  async onSubmit(values) {
    const {
      isPartner,
      source,
      isVerifyFlow,
      viewTypeData,
      isRecaptchaV3Enabled,
      logClientError: onRecaptchaError,
    } = this.props;
    const { location } = this.context;
    const { usernameEmail: username, password, rememberUser } = values;

    if (!username || !password) {
      return;
    }

    const recaptchaToken = isRecaptchaV3Enabled
      ? await getRecaptchaToken(recaptchaActions.emailSignIn, onRecaptchaError)
      : null;

    const authActionParams = {
      username,
      password,
      reCAPTCHAResponse: recaptchaToken,
      rememberUser,
      source,
      params: getPartnerParams(isPartner, location?.query),
      viewTypeData,
    };
    let shouldAttemptLogin = true;

    fbTrackSignIn();
    this.props?.logSignInClick(account.labels.email, source);

    if (isVerifyFlow) {
      shouldAttemptLogin = await this.verifyWithEmail(authActionParams);

      if (!shouldAttemptLogin) {
        return { [FORM_ERROR]: GENERAL_FAILURE };
      }
    }

    if (!isVerifyFlow || shouldAttemptLogin) {
      return await this.loginWithEmail(authActionParams);
    }
  }

  async loginWithEmail(authActionParams) {
    const { actions } = this.props;

    try {
      await actions.loginWithEmail(authActionParams);
    } catch (error) {
      return this.handleApiError(error);
    }
  }

  async verifyWithEmail(authActionParams) {
    let isSuccessful = true;

    try {
      await this.props.actions.verifyWithEmail(authActionParams);
    } catch (error) {
      isSuccessful = false;
    }
    return isSuccessful;
  }

  async handleOauthSignIn(...args) {
    const { actions, isVerifyFlow, viewTypeData } = this.props;

    if (isVerifyFlow) {
      await actions.verifyWithOauth(...args);
    }

    actions.loginWithOauth(...args, { viewTypeData });
  }

  handleApiError() {
    return {
      base: SIGN_IN_FAILED,
    };
  }

  handleShowPasswordClick(event) {
    event.preventDefault();
    this.setState({ showPassword: !this.state.showPassword });
  }

  getSignInButton({
    pristine,
    submitting,
    submitSucceeded,
    hasValidationErrors,
  }) {
    const { isVerifyFlow, isPaymentFlow, isPartner } = this.props;
    const { getLocalizedText } = this.context;

    const SignInButton = isPartner ? PremiumGoldButton : InkButton;
    const signInButtonLabel =
      isPaymentFlow || isVerifyFlow
        ? getLocalizedText(NEXT)
        : getLocalizedText(SIGN_IN);
    const isSubmitDisabled =
      pristine || submitting || submitSucceeded || hasValidationErrors;

    const signInButtonProps = {
      id: 'signInButton',
      type: 'submit',
      label: <span>{signInButtonLabel}</span>,
      isDisabled: isSubmitDisabled,
      className: classNames(authCss.authButton, {
        [authCss.isPartnerFlow]: isPartner,
      }),
    };

    if (isVerifyFlow) {
      Object.assign(signInButtonProps, {
        id: 'verifyButton',
        labelClassName: authCss.nextButtonLabel,
        hasRightSideIcon: true,
        icon: <ChevronRight className={authCss.nextButtonIcon} />,
      });
    }

    return <SignInButton {...signInButtonProps} />;
  }

  renderPersonalInfoFields(validFieldsMap, values) {
    const { showPassword } = this.state;
    const { isPartner } = this.props;
    const { getLocalizedText } = this.context;

    if (isPartner) {
      const additionalFieldProps = {
        ...inputMappedPartnerAuthStyles,
        floatingLabel: true,
        localizeErrorMessages: true,
        inlineErrors: true,
      };

      const isPartnerFlowHintStyle = { fontSize: '16px' };

      return (
        <>
          <div
            className={classNames(authCss.fieldsSection, authCss.isPartnerFlow)}
          >
            <div className="row">
              <div className="col-xs-12">
                <Field
                  component={Input}
                  className={classNames(
                    authCss.inputField,
                    'usernameField',
                    authCss.isPartnerFlow,
                  )}
                  hintText=""
                  autoCorrect="off"
                  autoCapitalize="none"
                  id="siUsernameEmail"
                  dataTestId="usernameEmail"
                  name="usernameEmail"
                  validate={isRequired(USERNAME_EMAIL_REQUIRED)}
                  label={getLocalizedText(USERNAME_EMAIL)}
                  isRequired
                  {...additionalFieldProps}
                />
                {validFieldsMap?.usernameEmail && <Checkmark {...svgProps} />}
              </div>
            </div>
          </div>
          <div
            className={classNames(authCss.fieldsSection, authCss.isPartnerFlow)}
          >
            <div className="row">
              <div className="col-xs-12">
                <Field
                  className={classNames(
                    authCss.inputField,
                    'passwordField',
                    authCss.isPartnerFlow,
                  )}
                  hintStyle={isPartnerFlowHintStyle}
                  hintText=""
                  component={Input}
                  id="siPassword"
                  dataTestId="password"
                  type="password"
                  name="password"
                  label={getLocalizedText(PASSWORD)}
                  validate={isRequired(PASSWORD_REQUIRED)}
                  isRequired
                  {...additionalFieldProps}
                  inputStyle={{
                    ...inputMappedPartnerAuthStyles.inputStyle,
                    paddingBottom: '5px',
                  }}
                />
                {validFieldsMap?.password && <Checkmark {...svgProps} />}
              </div>
            </div>
          </div>
        </>
      );
    }

    return (
      <div className={authCss.fieldsSection}>
        <div className="row">
          <div className="col-xs-12 col-sm-6">
            <Field
              component={Input}
              className={classNames(authCss.inputField, 'usernameField')}
              hintStyle={hintStyle}
              hintText={getLocalizedText(USERNAME_EMAIL)}
              autoCorrect="off"
              autoCapitalize="none"
              id="siUsernameEmail"
              dataTestId="usernameEmail"
              name="usernameEmail"
              validate={isRequired(USERNAME_EMAIL_REQUIRED)}
              isRequired
              {...inputStyles}
            />
          </div>
          <div className="col-xs-12 col-sm-6">
            <Field
              className={classNames(
                authCss.inputField,
                'passwordField',
                authCss.alongWithIcon,
              )}
              hintStyle={hintStyle}
              component={Input}
              id="siPassword"
              dataTestId="password"
              type={showPassword ? 'text' : 'password'}
              name="password"
              hintText={getLocalizedText(PASSWORD)}
              validate={isRequired(PASSWORD_REQUIRED)}
              isRequired
              {...inputStyles}
            />
            {!isPartner && values?.password && (
              <ShowPasswordButton
                showPassword={showPassword}
                onClick={this.handleShowPasswordClick}
              />
            )}
          </div>
        </div>
      </div>
    );
  }

  renderCheckboxFields() {
    const { handleChangeToForgotPassword, isPartner, isVerifyFlow } =
      this.props;
    const { getLocalizedText } = this.context;

    if (isVerifyFlow) {
      return null;
    }

    if (isPartner) {
      return (
        <div className={authCss.mediumSection}>
          <div
            className={classNames(
              authCss.mediumSectionInner,
              authCss.isPartnerFlow,
            )}
          >
            <Field
              component={CheckBox}
              customSpanClass={classNames(
                authCss.rememberMeCheckbox,
                authCss.isPartnerFlow,
              )}
              customCheckmarkClass={classNames(
                authCss.customCheckmarkClass,
                authCss.isPartnerFlow,
              )}
              labelClassName={authCss.rememberMeLabel}
              key="rememberUserCheckbox"
              dataTestId="rememberUser"
              id="rememberUser"
              type="checkbox"
              name="rememberUser"
              label={getLocalizedText(REMEMBER_ME_LABEL)}
              labelStyle={partnerAuthSharedStyles.labelStyle}
              isBold
            />
            <i key="siOptionsDivider" className={css.authOptionsDivider} />
            <UnderlineLink
              key="siForgotPasswordLink"
              /* biome-ignore lint/a11y/useSemanticElements: invalid lint error for `role` on React Component */
              role="button"
              tabIndex="0"
              id="signInForgotPasswordQuestion"
              onClick={handleChangeToForgotPassword}
              underlineClassName={classNames(
                css.underlineLink,
                css.forgotPassword,
                css.isPartnerFlow,
              )}
              className={classNames(
                authCss.link,
                authCss.forgotPasswordLink,
                authCss.isPartnerFlow,
              )}
              isDropDown
              isNotReactRouterLink
            >
              {getLocalizedText(FORGOT_PASSWORD_LINK)}
            </UnderlineLink>
          </div>
        </div>
      );
    }

    return (
      <div className={authCss.mediumSection}>
        <div className={authCss.mediumSectionInner}>
          <Field
            component={CheckBox}
            customSpanClass={authCss.rememberMeCheckbox}
            customCheckmarkClass={authCss.customCheckmarkClass}
            customCheckmarkUncheckedClass={
              authCss.customCheckmarkUncheckedClass
            }
            labelClassName={authCss.rememberMeLabel}
            key="rememberUserCheckbox"
            dataTestId="rememberUser"
            id="rememberUser"
            type="checkbox"
            name="rememberUser"
            label={getLocalizedText(REMEMBER_ME_LABEL)}
          />
        </div>
      </div>
    );
  }

  renderTopLevelErrorContainer(pristine, touched, submitErrors, errors) {
    const { isPartner } = this.props;

    return (
      <TopLevelErrorContainer
        className={classNames(authCss.largeSection, {
          [authCss.noSpacing]: !isPartner,
        })}
        customErrorListClass={classNames(authCss.customErrorListClass, {
          [authCss.isPartnerFlow]: isPartner,
        })}
        errorTextClassName={classNames(authCss.customErrorTextClass, {
          [authCss.isPartnerFlow]: isPartner,
        })}
        errorMessages={
          !pristine || touched ? { ...submitErrors, ...errors } : {}
        }
        isPartner={isPartner}
        fieldOrder={fieldOrder}
        touchedFields={touched}
      />
    );
  }

  render() {
    const {
      handleChangeToSignUp,
      failAuthNotice,
      successAuthNotice,
      handleChangeToForgotPassword,
      isDialog,
      isPaymentFlow,
      queryProduct,
      isPartner,
      source,
      isVerifyFlow,
    } = this.props;

    const { location, getLocalizedText } = this.context;
    const partnerParams = getPartnerParams(isPartner, location?.query);
    const isAlexaFlow =
      partnerParams.partnerId === vars.get('ALEXA_PARTNER_ID');
    const isOnAirFlow = source === SOURCE_ONAIR;

    return (
      <Form
        onSubmit={this.onSubmit}
        initialValues={{
          rememberUser: true,
        }}
      >
        {({
          errors,
          handleSubmit,
          pristine,
          submitting,
          submitSucceeded,
          submitErrors,
          hasValidationErrors,
          touched,
          values,
        }) => {
          const validFieldsMap = isPartner
            ? checkFieldsForValidInput(touched, errors)
            : null;

          return (
            <form name="signIn" onSubmit={handleSubmit}>
              <div
                data-testid="signIn"
                className={authCss.containerRestrictions}
              >
                {!isOnAirFlow && (
                  <OauthButtons
                    actionHandler={this.handleOauthSignIn}
                    loggingHandler={this.props.logSignInClick}
                    isDialog={isDialog}
                    isSignIn
                    isPartner={isPartner}
                    isPaymentFlow={isPaymentFlow}
                    source={source}
                    partnerParams={partnerParams}
                  />
                )}
                {isPartner &&
                  this.renderTopLevelErrorContainer(
                    pristine,
                    touched,
                    submitErrors,
                    errors,
                  )}
                <AuthSectionDivider isPartner={isPartner} />
                <GeneralNotices
                  key="signInGeneralNotices"
                  failAuthNotice={failAuthNotice}
                  successAuthNotice={successAuthNotice}
                />
                {!isPartner &&
                  this.renderTopLevelErrorContainer(
                    pristine,
                    touched,
                    submitErrors,
                    errors,
                  )}
                <div
                  className={classNames(authCss.section, {
                    [authCss.noSpacing]: !isPartner && !isVerifyFlow,
                  })}
                >
                  {this.renderPersonalInfoFields(validFieldsMap, values)}
                </div>
                {this.renderCheckboxFields()}
                <div className={authCss.section}>
                  <div className="row">
                    <div className="col-xs-12">
                      {this.getSignInButton({
                        pristine,
                        submitting,
                        submitSucceeded,
                        hasValidationErrors,
                      })}
                      {!isPartner && !isVerifyFlow && (
                        <UnderlineLink
                          key="siForgotPasswordLink"
                          /* biome-ignore lint/a11y/useSemanticElements: invalid lint error for `role` on React Component */
                          role="button"
                          tabIndex="0"
                          id="signInForgotPasswordQuestion"
                          onClick={handleChangeToForgotPassword}
                          underlineClassName={classNames(
                            css.underlineLink,
                            css.forgotPassword,
                          )}
                          className={classNames(
                            authCss.link,
                            authCss.forgotPasswordLink,
                            {
                              [authCss.isPartnerFlow]: isPartner,
                            },
                          )}
                          isDropDown
                          isNotReactRouterLink
                        >
                          {getLocalizedText(FORGOT_PASSWORD_LINK)}
                        </UnderlineLink>
                      )}
                      {!isVerifyFlow && !isAlexaFlow && (
                        <SecondaryContent
                          key="signInSecondaryContent"
                          isPartnerFlow={isPartner}
                          handler={handleChangeToSignUp}
                          onSignIn
                        />
                      )}
                      {isPartner && !!queryProduct?.freeTrialLength && (
                        <AuthFlowPromoOfferFinePrint
                          containerClassName={authCss.finePrintStyle}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </form>
          );
        }}
      </Form>
    );
  }
}

const mapDispatchToProps = {
  logSignInClick,
  logClientError,
};

// exported for testing
export function mapStateToProps(state) {
  return {
    isRecaptchaV3Enabled: selectExperiment(
      state,
      AUTH_EMAIL_SIGNIN_RECAPTCHA_ENABLED,
      false,
    ),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(SignInView);
