import classNames from 'clsx';
import get from 'lodash/get';
import PropTypes from 'prop-types';
import { useContext, useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { doFBSignIn, setupFBAuth } from 'src/common/utils/auth/fbAuth';
import { updateFbAuthSdkStatus } from '../../../actions/auth';
import { oAuthSdkStates } from '../../../constants/auth/oauth';
import {
  OAUTH_FB_API_SIGNIN_ERROR,
  OAUTH_FB_API_SIGNUP_ERROR,
} from '../../../constants/localizations/auth';
import { LocationAndLocalizationContext } from '../../../providers/LocationAndLocalizationProvider';
import Text from '../../Text';
import LoadingButton from '../../shared/button/LoadingButton';
import PillButton from '../../shared/button/PillButton';
import FacebookIcon from '../../shared/svgIcons/FacebookIcon';
import css from './oauth-button.module.scss';

// exported for testing
export const FacebookButton = ({
  actionHandler,
  partnerParams,
  source,
  onClick,
  fbAuthSdkStatus,
  isSignIn,
  isPartner,
  buttonText,
  actions,
}) => {
  const { getLocalizedText } = useContext(LocationAndLocalizationContext);
  const isErrorStatus = fbAuthSdkStatus === oAuthSdkStates.error;
  const errorText = isSignIn
    ? getLocalizedText(OAUTH_FB_API_SIGNIN_ERROR)
    : getLocalizedText(OAUTH_FB_API_SIGNUP_ERROR);

  // componentDidMount
  useEffect(() => {
    setupFBAuth((err) => {
      actions.updateFbAuthSdkStatus(
        err ? oAuthSdkStates.error : oAuthSdkStates.success,
      );
    }, isErrorStatus);
  }, []); /* eslint-disable-line react-hooks/exhaustive-deps */

  const handleClick = () => {
    if (onClick) onClick();
    doFBSignIn(actionHandler, source, partnerParams);
  };

  return (
    <>
      {fbAuthSdkStatus === oAuthSdkStates.loading ? (
        <LoadingButton
          className={classNames(css.facebookButton, {
            [css.isPartnerFlow]: isPartner,
          })}
        />
      ) : (
        <PillButton
          id="facebookOauthButton"
          className={classNames(css.facebookButton, {
            [css.isPartnerFlow]: isPartner,
          })}
          label={<Text name={buttonText} />}
          icon={<FacebookIcon width="24" height="24" viewBox="-1 -1 24 24" />}
          onClick={handleClick}
        />
      )}
      {isErrorStatus && <Text name={errorText} className={css.errorText} />}
    </>
  );
};

FacebookButton.propTypes = {
  actionHandler: PropTypes.func.isRequired,
  buttonText: PropTypes.string.isRequired,
  fbAuthSdkStatus: PropTypes.string.isRequired,
  actions: PropTypes.object.isRequired,
  onClick: PropTypes.func,
  partnerParams: PropTypes.object,
  source: PropTypes.string,
  isPartner: PropTypes.bool,
  isSignIn: PropTypes.bool,
};

export const mapStateToProps = (state) => ({
  fbAuthSdkStatus: get(state, 'auth.fbAuthSdkStatus'),
});

export const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({ updateFbAuthSdkStatus }, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(FacebookButton);
