import isEmpty from 'lodash/isEmpty';

/**
 * @param fields object - { email: true, password: true }
 * @param errorMessages object - { password: [localized.error.message] }
 * @returns object with fieldName as key, and inputFieldValid as bool value - { email: true, password: false }
 */
export default function checkFieldsForValidInput(fields, errorMessages) {
  const fieldKeys = Object.keys(fields);
  if (!isEmpty(fieldKeys)) {
    return fieldKeys.reduce((acc, currentKey) => {
      const hasBeenTouched = !!fields[currentKey];
      let hasError = false;

      if (hasBeenTouched && !isEmpty(errorMessages)) {
        hasError = errorMessages[currentKey]?.[0];
      }

      acc[currentKey] = hasBeenTouched && !hasError;
      return acc;
    }, {});
  }

  return {};
}
