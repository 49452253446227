import { Component } from 'react';
import appConfig from '../config';
import openStandardWindow from '../utils/window/openStandardWindow';
const { secureOrigin } = appConfig;

const DEFAULT_TIMEOUT = 1000;

export default function withStandardWindowControls(MyComponent) {
  return class WithStandardWindowControls extends Component {
    constructor(props) {
      super(props);

      this.openStandardWindow = this.openStandardWindow.bind(this);
      this.closeStandardWindow = this.closeStandardWindow.bind(this);
      this.handleIntervals = this.handleIntervals.bind(this);
    }

    componentWillUnmount() {
      this.closeStandardWindow();
    }

    handleIntervals() {
      if (!this.standardWindow) {
        window.clearInterval(this.interval);
      } else {
        this.standardWindow.postMessage('message', secureOrigin);
      }
    }

    openStandardWindow(windowConfig, responseHandler) {
      this.responseHandler = responseHandler;
      this.standardWindow = openStandardWindow(windowConfig);

      window.addEventListener('message', this.responseHandler);
      this.interval = window.setInterval(this.handleIntervals, DEFAULT_TIMEOUT);
    }

    closeStandardWindow() {
      if (this.standardWindow) {
        this.standardWindow.close();
        this.standardWindow = null;
      }
      window.removeEventListener('message', this.responseHandler);
    }

    render() {
      return (
        <MyComponent
          openStandardWindow={this.openStandardWindow}
          closeStandardWindow={this.closeStandardWindow}
          {...this.props}
        />
      );
    }
  };
}
