import classNames from 'clsx';
import PropTypes from 'prop-types';
import { useContext } from 'react';
import { Field, Form } from 'react-final-form';
import {
  hintStyle,
  inputStyles,
} from 'src/common/components/shared/inputSharedStyles';
import {
  RESET_PASSWORD_FAILED,
  RESET_PASSWORD_SUBMIT_BUTTON,
} from 'src/common/constants/localizations/auth';
import {
  CONFIRM_PASSWORD,
  PASSWORD,
  PASSWORDS_DONT_MATCH,
  PASSWORD_REQUIRED,
} from 'src/common/constants/localizations/shared';
import { LOGIN_PATH } from 'src/common/constants/paths';
import getRequiredLabel from 'src/common/utils/form/getRequiredLabel';
import { LocationAndLocalizationContext } from '../../providers/LocationAndLocalizationProvider';
import {
  baseError,
  composeValidators,
  isMatch,
  isRequired,
  isValidPassword,
} from '../../utils/form/validation';
import InkButton from '../shared/button/InkButton';
import Input from '../shared/form/Input';
import TopLevelErrorContainer from '../shared/form/TopLevelErrorContainer';
import authCss from './auth.module.scss';
import NeedHelp from './shared/NeedHelp';

const fieldOrder = ['password', 'confirmPassword'];

export function ResetPassword({ actions, handleChangeToSignIn, history }) {
  const {
    getLocalizedText,
    location: {
      query: { t: userToken },
    },
  } = useContext(LocationAndLocalizationContext);

  const onSubmit = async ({ confirmPassword }, form) => {
    try {
      await actions.resetPassword(userToken, confirmPassword);
      // We don't want to retain the url with the userToken in history, to prevent a situation
      // where the user will then try to use an invalid userToken, so let's replace the url state.
      history.replace(LOGIN_PATH);
      handleChangeToSignIn();
      form.restart();
    } catch (err) {
      return baseError(RESET_PASSWORD_FAILED);
    }
  };

  return (
    <Form onSubmit={onSubmit}>
      {({
        handleSubmit,
        submitErrors,
        errors,
        pristine,
        touched,
        hasValidationErrors,
        submitting,
      }) => (
        <form name="resetPassword" onSubmit={handleSubmit}>
          <div
            data-testid="resetPassword"
            className={authCss.containerRestrictions}
          >
            <TopLevelErrorContainer
              className={classNames(authCss.largeSection, authCss.noSpacing)}
              baseErrorClassName={authCss.baseErrorClassName}
              customErrorListClass={authCss.customErrorListClass}
              errorTextClassName={authCss.customErrorTextClass}
              errorMessages={
                !pristine || touched ? { ...errors, ...submitErrors } : {}
              }
              fieldOrder={fieldOrder}
              touchedFields={touched}
            />
            <div className={authCss.section}>
              <div className="row">
                <div className="col-xs-12">
                  <Field
                    key="rpPassword"
                    id="rpPassword"
                    component={Input}
                    dataTestId="password"
                    className="test-password"
                    name="password"
                    hintStyle={hintStyle}
                    hintText={getRequiredLabel(getLocalizedText(PASSWORD))}
                    type="password"
                    validate={composeValidators(
                      isRequired(PASSWORD_REQUIRED),
                      isValidPassword,
                    )}
                    isRequired
                    {...inputStyles}
                  />
                </div>
              </div>
            </div>
            <div className={authCss.section}>
              <div className="row">
                <div className="col-xs-12">
                  <Field
                    key="rpConfirmPassword"
                    id="rpConfirmPassword"
                    component={Input}
                    dataTestId="confirmPassword"
                    className="test-confirm-password"
                    hintStyle={hintStyle}
                    name="confirmPassword"
                    hintText={getRequiredLabel(
                      getLocalizedText(CONFIRM_PASSWORD),
                    )}
                    type="password"
                    validate={composeValidators(
                      isRequired(PASSWORD_REQUIRED),
                      isValidPassword,
                      isMatch('password', PASSWORDS_DONT_MATCH),
                    )}
                    isRequired
                    {...inputStyles}
                  />
                </div>
              </div>
            </div>
            <div className={authCss.section}>
              <div className="row">
                <div className="col-xs-12">
                  <InkButton
                    id="resetPasswordSubmit"
                    type="submit"
                    label={getLocalizedText(RESET_PASSWORD_SUBMIT_BUTTON)}
                    isDisabled={submitting || hasValidationErrors}
                  />
                </div>
              </div>
            </div>
            <div className={authCss.section}>
              <div className="row">
                <div className="col-xs-12">
                  <NeedHelp />
                </div>
              </div>
            </div>
          </div>
        </form>
      )}
    </Form>
  );
}

ResetPassword.propTypes = {
  actions: PropTypes.shape({
    resetPassword: PropTypes.func.isRequired,
  }),
  handleChangeToSignIn: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
};

export default ResetPassword;
