import classNames from 'clsx';
import isEmpty from 'lodash/isEmpty';
import omit from 'lodash/omit';
import PropTypes from 'prop-types';
import { useContext } from 'react';

import hasTouchedField from 'src/common/utils/form/hasTouchedField';
import { LocationAndLocalizationContext } from '../../../providers/LocationAndLocalizationProvider';
import commonFormCss from './form.module.scss';
import css from './top-level-error-container.module.scss';

function reduceFormErrors(errors = {}, touched, dedupe, baseOnly) {
  if (baseOnly && errors?.base) {
    return {
      base: errors.base,
    };
  }
  return Object.keys(errors).reduce((acc, cur) => {
    if (touched?.[cur] || cur === 'base') {
      // Deduplicate error message (i.e. error shown twice for password and confirm fields)
      const dedupeField = acc[dedupe?.[cur]]?.[0];
      if (dedupe && dedupeField === errors[cur]?.[0]) {
        return acc;
      }
      acc[cur] = errors[cur];
    }
    return acc;
  }, {});
}

const TopLevelErrorContainer = ({
  className,
  baseErrorClassName,
  errorTextClassName,
  errorMessages,
  fieldOrder,
  touchedFields,
  dedupeFields,
  isPartner,
  customErrorListClass,
}) => {
  const { getLocalizedText } = useContext(LocationAndLocalizationContext);
  const values = errorMessages._values || [];
  const reducedErrors = reduceFormErrors(
    errorMessages,
    touchedFields,
    dedupeFields,
    isPartner,
  );

  return (
    <div data-testid="errorContainer">
      {hasTouchedField(touchedFields) && !isEmpty(errorMessages) && (
        <div className={className}>
          {reducedErrors.base && (
            <div className="row">
              <div className="col-xs-12">
                <p
                  data-testid="topLevelBaseError"
                  className={classNames(
                    commonFormCss.errorText,
                    baseErrorClassName,
                    {
                      [css.isPartnerFlow]: isPartner,
                    },
                  )}
                >
                  {getLocalizedText(reducedErrors.base, ...values)}
                </p>
              </div>
            </div>
          )}
          {!isEmpty(omit(reducedErrors, 'base')) && (
            <div className={reducedErrors.base && css.innerSection}>
              <div className="row">
                <div className="col-xs-12">
                  <ul
                    data-testid="topLevelErrorList"
                    className={classNames(css.errorList, customErrorListClass)}
                  >
                    {fieldOrder.map((field) => {
                      const fieldErrors = reducedErrors[field];
                      if (Array.isArray(fieldErrors)) {
                        return fieldErrors.map((err) => {
                          const idKey = `${field}-${fieldErrors.indexOf(err)}`;
                          return (
                            <li
                              className={classNames(
                                commonFormCss.errorText,
                                errorTextClassName,
                              )}
                              data-testid={idKey}
                              key={idKey}
                            >
                              {Array.isArray(err)
                                ? getLocalizedText(...err)
                                : getLocalizedText(err)}
                            </li>
                          );
                        });
                      }
                      return null;
                    })}
                  </ul>
                </div>
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

TopLevelErrorContainer.propTypes = {
  className: PropTypes.string,
  baseErrorClassName: PropTypes.string,
  errorTextClassName: PropTypes.string,
  errorMessages: PropTypes.object,
  fieldOrder: PropTypes.array.isRequired,
  touchedFields: PropTypes.object.isRequired,
  isPartner: PropTypes.bool,
  customErrorListClass: PropTypes.string,
};

export default TopLevelErrorContainer;
