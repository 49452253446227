import { VIEW_TYPE } from 'src/common/constants/queryParams';

export default function changeRedirectIfNedeed(viewType, location) {
  const currentRedirect = location.query?.redirect;
  if (!currentRedirect) {
    return;
  }

  const redirectUrl = new URL(currentRedirect, window.location.href);
  const viewTypeInRedirect = redirectUrl.searchParams.get(VIEW_TYPE);
  if (viewTypeInRedirect && viewTypeInRedirect !== viewType) {
    redirectUrl.searchParams.set(VIEW_TYPE, viewType);
    return redirectUrl.toString();
  }
}
