import PropTypes from 'prop-types';
/* eslint-disable max-len */
const FacebookIcon = ({ width, height, viewBox }) => (
  <i>
    <svg
      width={width}
      height={height}
      viewBox={viewBox}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22 12C22 6.47758 17.5224 2 12 2C6.47758 2 2 6.47758 2 12C2 16.9908 5.65634 21.1281 10.4377 21.8791V14.8913H7.8979V12H10.4377V9.79635C10.4377 7.29035 11.9311 5.90517 14.2149 5.90517C15.3089 5.90517 16.4537 6.10064 16.4537 6.10064V8.56165H15.1922C13.9505 8.56165 13.5623 9.33229 13.5623 10.124V11.9999H16.3355L15.8925 14.8912H13.5623V21.879C18.3436 21.1294 21.9999 16.9922 21.9999 11.9999L22 12Z"
        fill="#1977F3"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.8926 14.8912L16.3355 11.9999H13.5624V10.124C13.5624 9.33368 13.9491 8.56165 15.1923 8.56165H16.4537V6.10064C16.4537 6.10064 15.3091 5.90517 14.2149 5.90517C11.9312 5.90517 10.4377 7.28897 10.4377 9.79635V12H7.89797V14.8913H10.4377V21.8791C10.9468 21.9592 11.4685 22 12.0001 22C12.5316 22 13.0534 21.9578 13.5624 21.8791V14.8913H15.8926L15.8926 14.8912Z"
        fill="#FEFEFE"
      />
    </svg>
  </i>
);
FacebookIcon.propTypes = {
  width: PropTypes.string.isRequired,
  height: PropTypes.string.isRequired,
  viewBox: PropTypes.string,
};
export default FacebookIcon;
