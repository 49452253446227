import classNames from 'clsx';
import PropTypes from 'prop-types';
import { Component } from 'react';
import { Field, Form } from 'react-final-form';
import {
  hintStyle,
  inputStyles,
} from 'src/common/components/shared/inputSharedStyles';
import { FAULTCODE_RESET_IN_PROGRESS } from 'src/common/constants/faultCodes';
import {
  BACK_TO_SIGN_IN,
  EMAIL_DOES_NOT_EXIST,
  FORGOT_PASSWORD_SUBMIT_BUTTON,
  RESET_PASSWORD_IN_PROGRESS,
} from 'src/common/constants/localizations/auth';
import {
  EMAIL_ADDRESS,
  EMAIL_REQUIRED,
} from 'src/common/constants/localizations/shared';
import getRequiredLabel from 'src/common/utils/form/getRequiredLabel';
import accountCategory from '../../constants/analytics/categoryActionLabel/account';
import { LocationAndLocalizationContext } from '../../providers/LocationAndLocalizationProvider';
import {
  baseError,
  composeValidators,
  isRequired,
  isValidEmail,
} from '../../utils/form/validation';
import InkButton from '../shared/button/InkButton';
import Input from '../shared/form/Input';
import TopLevelErrorContainer from '../shared/form/TopLevelErrorContainer';
import UnderlineLink from '../shared/link/UnderlineLink';
import authCss from './auth.module.scss';
import GeneralNotices from './shared/GeneralNotices';
import NeedHelp from './shared/NeedHelp';

export class ForgotPassword extends Component {
  static propTypes = {
    actions: PropTypes.shape({
      forgotPassword: PropTypes.func.isRequired,
    }),
    logAccountActivity: PropTypes.func.isRequired,
    successAuthNotice: PropTypes.string,
    handleChangeToSignIn: PropTypes.func.isRequired,
  };

  static contextType = LocationAndLocalizationContext;

  componentDidMount() {
    const { logAccountActivity } = this.props;
    const { actions, labels } = accountCategory;
    logAccountActivity(actions.show, [labels.forgotPassword]);
  }

  onSubmit = async (data, form) => {
    const { actions, logAccountActivity } = this.props;
    const { actions: categoryActions, labels } = accountCategory;
    const { email } = data;

    logAccountActivity(categoryActions.click, [labels.forgotPassword]);

    try {
      await actions.forgotPassword(email);
      form.restart();
    } catch (error) {
      const responseData = error.response?.data;
      switch (responseData?.faultCode) {
        case FAULTCODE_RESET_IN_PROGRESS:
          return baseError(RESET_PASSWORD_IN_PROGRESS);
        default:
          return { email: [EMAIL_DOES_NOT_EXIST] };
      }
    }
  };

  render() {
    const { handleChangeToSignIn, successAuthNotice } = this.props;

    const { getLocalizedText } = this.context;

    return (
      <Form onSubmit={this.onSubmit}>
        {({
          handleSubmit,
          dirty,
          hasValidationErrors,
          submitting,
          submitErrors,
          touched,
          errors,
        }) => (
          <form name="forgotPassword" onSubmit={handleSubmit}>
            <div
              data-testid="forgotPassword"
              className={authCss.containerRestrictions}
            >
              <GeneralNotices
                key="signInGeneralNotices"
                successAuthNotice={successAuthNotice}
                noticeTextClassName={authCss.generalNoticeText}
              />
              <TopLevelErrorContainer
                className={classNames(authCss.largeSection, authCss.noSpacing)}
                baseErrorClassName={authCss.baseErrorClassName}
                customErrorListClass={authCss.customErrorListClass}
                errorTextClassName={authCss.customErrorTextClass}
                errorMessages={
                  dirty || touched ? { ...submitErrors, ...errors } : {}
                }
                fieldOrder={['email']}
                touchedFields={touched}
              />
              <div className={authCss.section}>
                <div className={authCss.fieldsSection}>
                  <div className="row">
                    <div className="col-xs-12">
                      <Field
                        component={Input}
                        dataTestId="email"
                        className="test-email"
                        name="email"
                        id="fpEmail"
                        hintStyle={hintStyle}
                        hintText={getRequiredLabel(
                          getLocalizedText(EMAIL_ADDRESS),
                        )}
                        validate={composeValidators(
                          isRequired(EMAIL_REQUIRED),
                          isValidEmail,
                        )}
                        isRequired
                        {...inputStyles}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className={authCss.section}>
                <div className="row">
                  <div className="col-xs-12">
                    <InkButton
                      id="forgotPasswordSubmit"
                      type="submit"
                      label={getLocalizedText(FORGOT_PASSWORD_SUBMIT_BUTTON)}
                      isDisabled={submitting || hasValidationErrors}
                      className={authCss.forgotPasswordButton}
                    />
                  </div>
                </div>
              </div>
              <div className={authCss.section}>
                <div className="row">
                  <div className="col-xs-12">
                    <NeedHelp />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-xs-12">
                  <UnderlineLink
                    /* biome-ignore lint/a11y/useSemanticElements: invalid lint error for `role` on React Component */
                    role="button"
                    tabIndex="0"
                    data-testid="backToSignIn"
                    className={authCss.link}
                    onClick={handleChangeToSignIn}
                    isDropDown
                    isNotReactRouterLink
                  >
                    {getLocalizedText(BACK_TO_SIGN_IN)}
                  </UnderlineLink>
                </div>
              </div>
            </div>
          </form>
        )}
      </Form>
    );
  }
}

export default ForgotPassword;
