import classNames from 'clsx';
import interpolateComponents from 'interpolate-components';
import PropTypes from 'prop-types';
import { useContext } from 'react';
import { LocationAndLocalizationContext } from '../../../providers/LocationAndLocalizationProvider';
import Text from '../../Text';
import UnderlineLink from '../../shared/link/UnderlineLink';
import commonAuthCss from '../auth.module.scss';

const GeneralNotices = ({
  failAuthNotice,
  successAuthNotice,
  noticeTextClassName,
}) => {
  const { getLocalizedText } = useContext(LocationAndLocalizationContext);

  if (!failAuthNotice && !successAuthNotice) {
    return null;
  }

  return (
    <div
      data-testid="authGeneralNotices"
      className={commonAuthCss.generalNoticeWrapper}
    >
      {failAuthNotice && (
        <div className={commonAuthCss.largeSection}>
          <div className="row">
            <div className="col-xs-12">
              <p
                data-testid="failAuthNotice"
                className={classNames(
                  commonAuthCss.errorText,
                  noticeTextClassName,
                )}
              >
                <Text name={failAuthNotice} />
              </p>
            </div>
          </div>
        </div>
      )}
      {successAuthNotice && (
        <div className={commonAuthCss.largeSection}>
          <div className="row">
            <div className="col-xs-12">
              <p
                data-testid="successAuthNotice"
                className={classNames(
                  commonAuthCss.successText,
                  noticeTextClassName,
                )}
              >
                {interpolateComponents({
                  mixedString: getLocalizedText(successAuthNotice),
                  components: {
                    a: (
                      <UnderlineLink
                        role="button"
                        tabIndex="0"
                        key="emailLink"
                        className={classNames(
                          commonAuthCss.link,
                          commonAuthCss.generalNoticeInlineLink,
                        )}
                        to="mailto:support@tunein.com"
                        isNotReactRouterLink
                      />
                    ),
                  },
                })}
              </p>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

GeneralNotices.propTypes = {
  failAuthNotice: PropTypes.string,
  successAuthNotice: PropTypes.string,
  noticeTextClassName: PropTypes.string,
};

export default GeneralNotices;
