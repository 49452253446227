import classNames from 'clsx';
import PropTypes from 'prop-types';
import iconsCss from '../../../styles/icons.module.scss';
import ActionAlert from './ActionAlert';

import css from './action-alert.module.scss';

const ActionFailureAlert = ({ localizedText }) => (
  <ActionAlert
    localizedText={localizedText}
    iconClassName={classNames(css.failure, iconsCss['icon-wrong'])}
  />
);

ActionFailureAlert.propTypes = {
  localizedText: PropTypes.string.isRequired,
};

export default ActionFailureAlert;
