import cssVars from '../../styles/variables';

export const inputStyles = {
  style: {
    height: '48px',
  },
  inputStyle: {
    border: `1px solid ${cssVars['--elephant-grey']}`,
    borderRadius: '6px',
    padding: '18px 0px 18px 18px',
  },
  inputFocusStyle: {
    borderColor: cssVars['--secondary-color-5'],
  },
  inputErrorStyle: {
    border: 'none',
  },
};

export const hintStyle = {
  fontFamily: cssVars['--primary-font'],
  fontSize: '14px',
  lineHeight: '19px',
  padding: '18px 0px 18px 18px',
  color: cssVars['--cloud-grey'],
};
