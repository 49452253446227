import PropTypes from 'prop-types';
import { useContext } from 'react';
import { LocationAndLocalizationContext } from '../../../providers/LocationAndLocalizationProvider';
import css from './action-alert.module.scss';

const ActionAlert = ({ localizedText, children }) => {
  const { getLocalizedText } = useContext(LocationAndLocalizationContext);

  return (
    <div data-testid="actionAlert" className={css.container}>
      {children && children}
      <p className={css.localizedText}>{getLocalizedText(localizedText)}</p>
    </div>
  );
};

ActionAlert.propTypes = {
  localizedText: PropTypes.string.isRequired,
  children: PropTypes.node,
};

export default ActionAlert;
