import isFunction from 'lodash/isFunction';

import { freeTrialLengthWithTrialPeriodKeys } from 'src/common/constants/subscription/products';

export function getFreeTrialLengthWithPeriodLocalizationsKeys(isMonthlyTrial) {
  return isMonthlyTrial
    ? freeTrialLengthWithTrialPeriodKeys.months
    : freeTrialLengthWithTrialPeriodKeys.days;
}

/**
 *
 * @param {function} getLocalizedText
 * @param {string} freeTrialLength
 * @param {boolean} isMonthlyTrial
 * @param {boolean} isSingularRequired
 * @returns {string} i.e. 7 days, 1 month, etc.
 * Used to create a localized string, which consists of a freeTrialLength + trialPeriod (i.e 3 days)
 */
export function createFreeTrialLengthWithTrialPeriod(
  getLocalizedText,
  freeTrialLength,
  isMonthlyTrial,
  isSingularRequired,
) {
  const numFreeTrialLength = Number.parseInt(freeTrialLength, 10);

  if (!Number.isNaN(numFreeTrialLength) && isFunction(getLocalizedText)) {
    const isTrialPeriodPlural = !isSingularRequired && numFreeTrialLength > 1;
    const freeTrialLengthWithPeriodSelectedKeys =
      getFreeTrialLengthWithPeriodLocalizationsKeys(isMonthlyTrial);

    return getLocalizedText(
      isTrialPeriodPlural
        ? freeTrialLengthWithPeriodSelectedKeys.plural
        : freeTrialLengthWithPeriodSelectedKeys.singular,
      {
        freeTrialLength: numFreeTrialLength,
      },
    );
  }
}
