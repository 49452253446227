import classNames from 'clsx';
import PropTypes from 'prop-types';
import css from './show-password-button.module.scss';

const ShowPasswordButton = ({ showPassword, onClick }) => (
  <button
    data-testid="showPasswordButton"
    className={css.showPasswordButton}
    onClick={onClick}
  >
    <i
      className={classNames(css.visibilityIcon, {
        [css.visibilityOnIcon]: !showPassword,
        [css.visibilityOffIcon]: showPassword,
      })}
    />
  </button>
);

ShowPasswordButton.propTypes = {
  showPassword: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default ShowPasswordButton;
