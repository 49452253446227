import { fetchWithRetry } from '@tunein/web-utils';
import {
  recaptchaSecret,
  recaptchaSiteKey,
  recaptchaVerifyEndpoint,
} from '../../constants/auth/recaptcha';

function generateVerifyUrl(token, ipAddress) {
  const url = new URL(recaptchaVerifyEndpoint);

  url.search = new URLSearchParams({
    secret: recaptchaSecret,
    response: token,
    remoteip: ipAddress,
  }).toString();

  return url.toString();
}

function readyCallback(action, resolve) {
  return async () => {
    const token = await window.grecaptcha.execute(recaptchaSiteKey, { action });
    resolve(token);
  };
}

export function getRecaptchaToken(action, onError) {
  if (!window.grecaptcha) {
    onError({
      message: 'Recaptcha V3: grecaptcha not defined',
      context: { action },
    });
    return null;
  }

  return new Promise((resolve) => {
    try {
      window.grecaptcha.ready(readyCallback(action, resolve));
    } catch (e) {
      onError({
        message: 'Recaptcha V3: error fetching token',
        context: { action },
      });
      resolve(null);
    }
  });
}

export async function fetchRecaptchaScore(originalAction, token, ipAddress) {
  const verifyUrl = generateVerifyUrl(token, ipAddress);
  const {
    success,
    action,
    score,
    'error-codes': errorCodes,
  } = await fetchWithRetry(verifyUrl, { method: 'POST' });

  if (success && action === originalAction) {
    return score;
  }

  const errorCodeString = errorCodes?.toString() || 'no error codes available';
  throw new Error(`Recaptcha V3: error verifying token (${errorCodeString})`);
}
